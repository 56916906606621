.section__08 {
  .container {
    overflow: hidden;
  }

  h3 {
    font-family: "Oswald";
    font-size: 4rem;
    font-weight: 500;
    text-align: center;
    color: #283248;
  }

  &__header {
    h3 {
      @include media-breakpoint-up(sm) {
        margin: 6rem 0 7rem;
      }
      @include media-breakpoint-only(xs) {
        margin: 3rem 0;
      }
    }
  }

  &__part1 {
    svg {
      width: 100%;
      @include media-breakpoint-only(md) {
        rect {
          height: 15px;
        }
      }
      @include media-breakpoint-only(sm) {
        rect {
          height: 20px;
        }
      }
      @include media-breakpoint-only(xs) {
        rect {
          height: 8px;
        }
      }
    }
    &__description {
      padding: 2rem;
      strong {
        font-family: "Oswald";
        font-weight: 600;
        color: #005ba6;
      }
    }
  }

  &__skyline {
    width: 100%;
  }

  &__part2 {
    margin: 10rem 0;
    .number {
      &--large {
        color: #f9a01b;
        font-size: 6.25rem;
      }
      margin-bottom: -3rem;
      @include media-breakpoint-only(xs) {
        margin-bottom: -5rem;
      }
    }

    .stat19__two {
      margin: 0 3rem;
    }
    .stat {
      @include media-breakpoint-only(sm) {
        font-size: 1.3rem;
      }
    }
  }

  &__part4 {
    margin: 6rem 0;
    p {
      padding-left: 0;
    }
    &__item {
      display: flex;
      min-height: 15rem;
      max-height: 20rem;
      img {
        // mix-blend-mode: multiply;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
      .number {
        font-family: "Oswald";
        font-size: 4rem;
        @include media-breakpoint-only(xl) {
          font-size: 7rem;
          padding-bottom: 2rem;
        }
        @include media-breakpoint-only(lg) {
          font-size: 6rem;
          padding-bottom: 1rem;
        }
      }
      font-weight: 700;
      text-align: center;
      color: #fff;
      position: relative;
      .number {
        line-height: 6rem;
      }
      .title {
        font-size: 1.5rem;
      }
      &__inner {
        position: absolute;
        top: 0;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      }
      &:hover {
        color: #fff;
      }
      overflow: hidden;
      @include media-breakpoint-up(sm) {
        &:hover img {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
          color: #fff;
          transition: transform 0.7s;
        }
      }
    }
    .description {
      margin-bottom: 4rem;
      ul {
        margin-left: -2.5rem;
        li {
          background: url("/assets/images/06_empowering-diverse-learners/bullet-pt.svg")
            no-repeat left top;
          padding: 0.2rem 0.5rem 1.2rem 3.5rem;
          list-style: none;
          @include media-breakpoint-only(xs) {
          background-size: 10%;
          padding: .1rem .5rem 1.2rem 3rem;
          }
        }
      }
    }

    &__item1-img {
      background-color: #c9a6ce;
    }

    &__item2-img {
      background-color: #7677b8;
    }

    &__item3-img {
      background-color: #71c6e7;
      .title {
        top: 30%;
      }
    }

    &__item4-img {
      background-color: #11a1dc;
      .title {
        top: 30%;
      }
    }

    &__item5-img {
      background-color: #fdcc09;
    }

    &__item6-img {
      background-color: #99cc66;
    }

    &__item7-img {
      background-color: #03af4b;
    }

    .description {
      font-size: 1.25rem;
      p {
        margin-top: 2rem;
        font-weight: bold;
      }
      .link {
        font-weight: bold;
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  &__part5 {
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
    .row {
      height: 100%;
    }

    .colour-bar-small {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      transform: rotate(90deg);
      svg {
        transform: scale(0.5, 1.2);
      }
    }

    &__description {
      padding-top: 5rem;
      color: $gbc-blue;
      img {
        padding: 3rem 2rem 2rem;
      }
      p {
        margin: 0;
        @include media-breakpoint-only(lg) {
          font-size: 1.75rem;
        }
        @include media-breakpoint-only(xl) {
          font-size: 2rem;
          margin: 0;
        }
      }
    }

    &__number {
      color: $gbc-blue;
      font-family: "Oswald";
      font-size: 2rem;
    }

    @include media-breakpoint-up(sm) {
      .colour-bar-small-bg {
        background: url("/assets/images/common_ui/colour-bar-small.png")
          no-repeat left;
        background-size: 8px 80%;
        padding: 3rem 0 3rem 3rem;
      }
    }

    &__graphic {
      padding: 6rem 3rem 5rem;
      position: relative;
      &__header {
        width: 50%;
        position: absolute;
        top: 35%;
        left: 25%;
        text-align: center;
        img {
          width: 200px;
          height: 10px;
        }
        span {
          color: $gbc-blue;
          font-size: 5rem;
          font-family: "Oswald";
        }
      }
    }
    &__figures {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 -15px;
    }

    &__figure {
      position: relative;
      text-align: center;
    }

    .pointer-tl {
      width: 90px;
      height: 50px;
      right: 0px;
      top: 27px;

      @include media-breakpoint-only(lg) {
        width: 65px;
        right: -24px;
      }
    }
    .pointer-tr {
      width: 90px;
      height: 50px;
      left: -20px;
      top: 27px;
      @include media-breakpoint-only(lg) {
        left: -30px;
        width: 65px;
      }
    }
    .pointer-bl {
      width: 90px;
      height: 33px;
      right: -10px;
      top: -7px;
      @include media-breakpoint-only(lg) {
        width: 65px;
      }
    }
    .pointer-br {
      width: 90px;
      height: 32px;
      top: -2px;
      left: 12px;
      @include media-breakpoint-only(lg) {
        width: 65px;
      }
    }
  }

  &__part6 {
    h3 {
      margin: 8rem auto 6rem;
      text-transform: uppercase;
    }
    h4 {
      font-family: $font-family-sans-serif;
      font-weight: bold;
      margin-bottom: 2rem;
      @include media-breakpoint-only(sm) {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }
    }
    p {
      padding-left: 0;
      font-size: 2rem;
      font-weight: bold;
    }
    ul {
      font-weight: normal;
      list-style-type: none;
      font-size: 1.5rem;
      line-height: 3rem;
      padding-left: 0;
      @include media-breakpoint-only(sm) {
      font-size: 1rem;
      line-height: 2rem;
      }
    }
    .number--extra-small {
      font-family: "Oswald";
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 10rem;
      @include media-breakpoint-down(sm) {
        font-size: 2rem !important;
      }
    }
    .row {
      margin-bottom: 1rem;
    }
    &__list {
      @include media-breakpoint-only(xs) {
        .colour-block.spacing {
          padding: 0 0 1rem;
        }
      }
    }
    .colour-block.spacing {
      padding: 0 0 2rem;
    }
  }

  &__programs-offered {
    padding: 3rem 0;
    color: $gbc-blue;
    &__one {
      font-size: 2rem;
      font-weight: normal;
      @include media-breakpoint-down(sm){
        font-size: 1.5rem;
        margin-left: -1rem;
      }
    }
    &__two {
      font-size: 1.8rem;
      font-weight: 600;
      margin-top: 2rem;
      @include media-breakpoint-only(sm) {
        margin-left: -2rem !important;
        margin-top: 1.5rem;
        font-weight: normal;
      }
      @include media-breakpoint-only(xs) {
        margin-left: -1rem !important;
        margin-top: 1rem;
        font-weight: normal;
        font-size: 1rem;
      }

    }
    .number__two {
      span {
        font-size: 10.5rem;
        @include media-breakpoint-only(sm) {
          font-size: 5rem;
        }
        @include media-breakpoint-only(xs) {
          font-size: 4rem;
        }
      }
    }
    
      .colour-block.spacing {
        @include media-breakpoint-up(sm) { 
          padding: 1rem 0;
          margin-bottom: -2rem;
        }
        @include media-breakpoint-only(xs) { 
          padding: 1rem 0;
          margin-bottom: -1rem;
        }
      }
  }

  .stat18 {
    .swipe__mask {
      width: 100%;
    }
  }
}

.bullet {
  margin-left: -3rem;
  margin-right: 0.25rem;
}

.section__08__pie-chart {
  &--1 {
    .figure__one {
      margin-top: 1rem;
      margin-right: 2rem;
    }
    .figure__two {
      margin-top: 1rem;
      margin-left: 5rem;
    }
    .figure__three {
      margin-bottom: -1rem;
      margin-left: 1rem;

      @include media-breakpoint-only(xs) {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
    margin-bottom: 3rem;
  }

  &--2 {
    .figure__one {
      margin-right: 3rem;
      margin-top: 1rem;
      padding-right: 1rem;
    }
    .figure__two {
      margin-top: 1rem;
      margin-left: 5rem;
      .pointer-tr {
        left: -25px;


        @include media-breakpoint-only(md) {
          left: -56px;
        }
      }
    }
    .figure__three {
      margin-right: 2rem;
      margin-bottom: 2rem;

      @include media-breakpoint-only(md) {
        margin-bottom: -1rem;
      }
    }
    .figure__four {
      margin-left: 5rem;
      margin-bottom: 1rem;

      @include media-breakpoint-up(xl) {
        margin-left: 9rem;
      }
      @include media-breakpoint-only(lg) {
        padding-left: 3rem;
      }
      @include media-breakpoint-only(md) {
        margin-bottom: -2rem;
        margin-left: 7rem;
      }
      @include media-breakpoint-only(sm) {
        margin-left: 7rem;
      }
      @include media-breakpoint-only(xs) {
        margin-bottom: -1rem;
      }
    }
    .pointer-br {
      width: 69px;
      height: 32px;
      top: -2px;
      left: -14px;
      @include media-breakpoint-only(lg) {
        top: 0px;
        left: 26px;
      }
      @include media-breakpoint-only(sm) {
        left: 3px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .section__08 {
    &__part4 {
      margin: 0;
     &__container {
      margin: 5rem auto;
      padding:0 30px !important;
    }
      &__item {
        .title {
          font-size: 1.25rem;
        }
      }
    }
    &__part5 {
      &__description {
        text-align: center;
        & .colour-bar-small-bg {
          padding-top: 1rem;
        }
      }
    }
    &__part6 {
      h3 {
        text-transform: uppercase;
        font-size: 2rem;
      }
      ul {
        font-size: 1.25rem;
        line-height: 2.25rem;
      }
      .number--extra-small {
        margin-bottom: 6rem;
      }
      // ul {
      //   line-height: 2.5rem;
      // }
      // &__list {
      //   padding:0 5rem;
      //   margin-bottom: -4rem;
      //   .number--extra-small {
      //     font-size: 2rem;
      //   }
      // }
    }
  }
}

@include media-breakpoint-down(sm) {
  .section__08 {
    h3 {
      font-size: 2.25rem;
    }
    .section__08__part5__description img {
      padding: 2rem 1rem 1rem;
    }
  }
}

@include media-breakpoint-only(sm) {
  .section__08 {
    &__pie-chart--2 {
      .section__08__part5__graphic {
        padding-bottom: 7rem;
      }
    }
  }
}
@include media-breakpoint-only(xs) {
  .section__08 {
    &__part2 {
      margin: 0;
      .stat19__two {
        padding: 3rem 0 23rem;
      }
    }
    &__part5 {
      &__graphic {
        padding: 8rem 1rem;

        &__header {
          span {
            font-size: 3.5rem;
          }
          img {    font-size: 0.8rem;
            width: 85%;
          }
        }
      }
    }

    &__pie-chart--one {
      margin-bottom: 3rem;
    }

    .pointer-ascending,
    .pointer-descending,
    .pointer-vertical,
    .pointer-horizontal {
      position: absolute;
      width: 2px;
      background: #7f7a7a;
      height: 60px;
    }
    .pointer-vertical {
      left: 75%;
      top: 100%;
    }
    .pointer-horizontal {
      height: 2px;
      width: 50px;
      right: -20px;
      top: 29px;
    }
    .pointer-ascending {
      transform: skew(-35deg);
      left: 31%;
      height: 30px;
      top: 120%;
    }
    .pointer-descending {
      transform: skew(35deg);
      height: 40px;
      left: 50%;
      top: 120%;
    }

    .figure__four {
      .pointer-descending {
        left: 50%;
        bottom: 120%;
        top: unset;
      }
    }
    .figure__three {
      .pointer-ascending {
        left: 62%;
        top: unset;
        bottom: 120%;
      }
      .pointer-descending {
        left: 40%;
        bottom: 120%;
        top: unset;
      }
    }

    &__pie-chart--2 {
      .figure__one {
        margin-right: 1rem;
        margin-top: 0rem;
      }
      .figure__three {
        margin-bottom: 0;
        .pointer-ascending {
          height: 48px;
        }
      }
      .figure__four {
        margin-left: 1rem;
        padding-left: 2rem;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .section__08 {
    h3 {
      font-size: 2.75rem;
    }

    &__pie-chart--2 {
      .pointer-br {
        left: -2px;
        top: 0;
      }
      .figure__two {
        margin-left: 8rem;
      }
    }
    &__part6 {
      &__list-two {
        .spacing {
          padding-top: 0rem !important;
        }
      }
    }
  }
}

// @media only screen and (max-width: 767px) {
//   .section__08 {
//     h3 {
//       font-size: 2.5rem;
//       margin: 3rem 2rem 2rem;
//     }
//     &__part1 {
//       .number {
//         margin-bottom: -2rem;
//       }
//       &__description {
//         span {
//           font-size: 1.25rem;
//           &.smaller {
//             font-size: 1.125rem;
//           }
//         }
//       }
//     }

//     &__part2 {
//       margin: 5rem 0;
//       .number {
//         margin-bottom: -2rem;
//         &--large {
//           font-size: 8rem;
//         }
//         &__background {
//           font-size: 12rem;
//         }
//       }
//       img {
//         width: 13rem;
//         height: 15rem;
//         margin-top: 2rem;
//       }
//     }

//     // Images:
//     &__part4 {

//       > div {
//         margin: auto;
//       }
//       &__item {
//         font-weight: 700;
//         text-align: center;
//         color: #fff;
//         position: relative;
//         .number {
//           line-height: 8rem;
//         }
//         .title {
//           font-size: 1.5rem;
//         }
//         height: 15rem;
//         img {
//           width: 100%;
//           height: 100%;
//         }
//         &__inner {
//           position: absolute;
//           top: 0;
//         }
//       }

//     }

//     &__programs-offered {
//       .number {
//         &__one {
//           .number--mild-large {
//             font-size: 5rem;
//           }
//         }
//         &__two {
//           .number--mild-large {
//             font-size: 4rem;
//           }
//         }
//       }
//       &__one {
//         margin-left: 0 !important;
//         font-size: 1.25rem;
//       }
//       &__two {
//         margin-left: 0 !important;
//         font-size: 1rem;
//       }
//     }

//     &__part5 {
//       &__description {
//         img {
//           width: 20rem;
//           height: 13rem;
//         }
//       }

//       .colour-bar-small-bg {
//         padding-left: 4rem;
//       }
//     }

//     &__part6 {
//       h3 {
//         margin: 3rem 6rem 2rem;
//       }
//       ul {
//         line-height: 2.5rem;
//       }
//       &__list {
//         padding:0 5rem;
//         margin-bottom: -4rem;
//         .number--extra-small {
//           font-size: 2rem;
//         }
//       }
//     }
//   }
// }

@include media-breakpoint-only(sm) {
  .section__08 {
    &__part4 {
      &__item {
        .title {
          font-size: 1.25rem;
        }
      }
    }
    &__part6 {
      &__list-two {
        .spacing {
          padding-top: 4.8rem !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .offset-lg-3-half {
    margin-left: 29.16%;
  }
  .section__08 {
    &__part2 {
      .stat19__two {
        margin: -3rem 6rem 0;
      }
    }

    .number--large {
      font-size: 8rem; // Standardize w/ everywhere?
    }
  }
}

@media screen and (max-width: 520px) {
  .section__08 {
    &__part5 {
      font-size: 0.8rem;
      &__graphic__header img {
        height: 4px;
      margin-top: -6px;
      }
      &__figure {
        font-size: 0.8rem;
      }
    }
  }
}