@import url("//hello.myfonts.net/count/3a1335");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #005293;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: "OpenSans", "Open Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "OpenSans", "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: black;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 860px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 82, 147, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #005293;
  border-color: #005293; }
  .btn-primary:hover {
    color: #fff;
    background-color: #003d6d;
    border-color: #003660; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 108, 163, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #005293;
    border-color: #005293; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #003660;
    border-color: #002e53; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 108, 163, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #005293;
  border-color: #005293; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #005293;
    border-color: #005293; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 82, 147, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #005293;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #005293;
    border-color: #005293; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 82, 147, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #000;
  text-decoration: none; }
  .btn-link:hover {
    color: black;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #005293; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #005293; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 767.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #005293 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #003660 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #005293 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #005293 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #002747 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1200px !important; }
  .container {
    min-width: 1200px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'Handelson-One';
  src: url("/assets/fonts/3A1335_0_0.eot");
  src: url("/assets/fonts/3A1335_0_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/3A1335_0_0.woff2") format("woff2"), url("/assets/fonts/3A1335_0_0.woff") format("woff"), url("/assets/fonts/3A1335_0_0.ttf") format("truetype"); }

p {
  font-size: 1.5rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    p {
      font-size: 1.25rem; } }
  @media (max-width: 991.98px) {
    p {
      font-size: 1.175rem; } }

h1 {
  font-family: "Handelson-One"; }

h2 {
  font-family: "Handelson-One";
  color: #005293; }
  h2 span {
    font-family: "Oswald";
    color: #000; }

h3 {
  font-family: "Oswald"; }
  h3.alternate {
    font-family: "OpenSans", "Open Sans", sans-serif; }

h4 {
  font-family: "Oswald"; }

.back-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem;
  z-index: 9999;
  border-radius: 3px; }
  @media (max-width: 991.98px) {
    .back-to-top {
      right: 0.5rem; } }
  .back-to-top img {
    width: 2.2rem;
    height: 1rem;
    align-self: center; }
  .back-to-top span {
    font-family: "OpenSans", "Open Sans", sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    color: #005ba6;
    margin-top: 0.5rem; }
    @media (max-width: 991.98px) {
      .back-to-top span {
        text-indent: 5px; } }

.section__00 {
  background: #005293; }
  .section__00 .container {
    height: 97px; }
    .section__00 .container svg {
      margin: 19px auto;
      margin-left: -2rem; }

@media only screen and (max-width: 767px) {
  .section__00 .container {
    height: 58px; }
    .section__00 .container svg {
      height: 28px;
      width: 43px;
      margin: 18px auto; } }

.colourblock {
  display: flex;
  width: 100%; }
  .colourblock > div {
    flex: 1;
    height: 1rem; }
  .colourblock__1 {
    background: #FF6D22; }
  .colourblock__2 {
    background: #FFA100; }
  .colourblock__2 {
    background: #FFA100; }
  .colourblock__3 {
    background: #C9A5D7; }
  .colourblock__4 {
    background: #7577B9; }
  .colourblock__5 {
    background: #72C7E7; }
  .colourblock__6 {
    background: #00A1DE; }
  .colourblock__7 {
    background: #FECB00; }
  .colourblock__8 {
    background: #99CC66; }
  .colourblock__9 {
    background: #00AF3F; }

.section__01__header {
  background: url("/assets/images/01_main_header/header.jpg") no-repeat bottom right;
  background-size: 44% auto;
  background-position: 105% 115%; }
  @media (max-width: 1199.98px) {
    .section__01__header .row-fluid {
      flex-direction: column;
      height: 100%; } }
  .section__01__header h1 {
    max-width: 567px;
    margin: 30px auto 0px;
    font-size: 95px;
    text-align: center;
    color: #005ba6; }
  .section__01__header p {
    font-family: "Oswald";
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 8px;
    text-align: center;
    color: #283248;
    text-transform: uppercase; }
  .section__01__header .title {
    padding: 6rem 0 1rem; }
    @media (min-width: 992px) {
      .section__01__header .title {
        padding: 6rem 0;
        margin-left: -6rem; } }
    @media (min-width: 1440px) {
      .section__01__header .title {
        padding: 10rem 0; } }

.section__01__introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../images/01_main_header/HeaderPattern_768x571-01.png");
  background-size: auto;
  overflow: hidden; }
  .section__01__introduction__content {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #005ba6;
    margin: 4.5rem auto;
    margin-bottom: 2.5rem; }
  .section__01__introduction p {
    margin-bottom: 2rem; }

@media (max-width: 767.98px) {
  .section__01__header {
    background-size: 100% auto;
    background-position: 20px 110%; }
    .section__01__header .title {
      margin-bottom: 18rem; }
    .section__01__header h1 {
      font-size: 3rem;
      text-align: center;
      color: #005ba6; }
    .section__01__header p {
      font-size: 1rem; }
  .section__01__colour-divider {
    display: none; }
  .section__01__introduction__content {
    padding: 0 3rem;
    margin: 3rem auto 0; }
    .section__01__introduction__content p {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.75rem; }
  .section__01__introduction .btn-pdf {
    margin-bottom: 2rem; } }

@media screen and (min-width: 500px) and (max-width: 768px) {
  .section__01__header .title {
    margin-bottom: 22rem; }
  .section__01__header h1 {
    font-size: 4rem; } }

@media screen and (min-width: 620px) and (max-width: 768px) {
  .section__01__header .title {
    margin-bottom: 27rem; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__01__header {
    background-size: 100% auto;
    background-position: 20px 110%; }
    .section__01__header .title {
      margin-bottom: 34rem; }
    .section__01__header h1 {
      font-size: 6rem; } }

@media (min-width: 1440px) {
  .section__01__header {
    background-size: auto 80%; } }

.section__02__network-description {
  margin: 9rem auto 14rem; }

.section__02__img-medium {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__02__img-medium {
      height: 10rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__02__img-medium {
      height: 8rem; } }
  @media (max-width: 767.98px) {
    .section__02__img-medium {
      height: auto; } }
  .section__02__img-medium img {
    width: 100%;
    height: auto;
    padding: 2rem; }

.section__02 .padding {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.section__02 .stat__two .pointer-tl {
  width: 40px;
  right: 0;
  background-size: 103px; }

.section__02 .pointer-tl {
  width: 4rem;
  height: 50px;
  right: -1.5rem;
  top: 4rem; }

.section__02 .pointer-tr {
  width: 4rem;
  height: 50px;
  left: -21px;
  top: 64px; }

.section__02 .pointer-bl {
  width: 4rem;
  height: 40px;
  right: -1.5rem;
  top: 30px; }

.section__02 .pointer-br {
  width: 4rem;
  height: 50px;
  left: -21px;
  top: 50px; }

.section__02__colour-blocks-vertical {
  position: absolute;
  left: 50%;
  z-index: 99; }

.section__02__part1 .stat {
  font-size: 1.75rem; }

.pie-chart {
  position: relative; }
  .pie-chart__figures {
    position: absolute;
    pointer-events: none;
    width: 100%;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 2rem; }
  .pie-chart__graphic {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .pie-chart__graphic img {
      width: 50%; }
  .pie-chart h3 {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    font-size: 2rem;
    margin-top: -1em;
    left: 0;
    right: 0; }
  .pie-chart .number--medium {
    line-height: 1; }

@media (max-width: 767.98px) {
  .section__02__colour-block__one img {
    height: 1rem; }
  .section__02 .stat {
    font-size: 1.375rem; }
    .section__02 .stat .number--large {
      font-size: 8rem; }
    .section__02 .stat .number__background {
      font-size: 10.5rem; }
  .section__02__network-description {
    margin-top: -2rem;
    margin: 9rem auto 5rem; }
    .section__02__network-description .stat {
      padding-top: 0;
      padding-bottom: 1rem; }
      .section__02__network-description .stat .number--medium {
        font-size: 5.625rem; }
  .section__02__colour-blocks-vertical {
    display: none; }
  .section__02__colour-block {
    width: 100%; }
  .pie-chart .pointer-ascending,
  .pie-chart .pointer-descending,
  .pie-chart .pointer-vertical,
  .pie-chart .pointer-horizontal {
    position: absolute;
    width: 2px;
    background: #7f7a7a;
    height: 60px; }
  .pie-chart .pointer-vertical {
    left: 75%;
    top: 100%; }
  .pie-chart .pointer-horizontal {
    height: 2px;
    width: 50px;
    right: -20px;
    top: 29px; }
  .pie-chart .pointer-ascending {
    transform: skew(-45deg);
    left: 77%;
    height: 30px;
    top: -25%; }
  .pie-chart .pointer-descending {
    transform: skew(35deg);
    height: 40px;
    left: 117%;
    top: 62%; }
  .pie-chart .stat {
    position: absolute;
    width: 45%; }
  .pie-chart .stat__one {
    top: 4rem;
    left: 15px; }
    .pie-chart .stat__one .pointer-tl {
      right: 19rem;
      top: 25rem;
      display: none; }
  .pie-chart .stat__two {
    top: 14rem; }
    .pie-chart .stat__two .pointer-tl {
      width: 90px;
      left: 10rem;
      top: 20rem;
      background-size: 120px;
      display: none; }
  .pie-chart .stat__three {
    top: 23rem;
    left: 60px; }
    .pie-chart .stat__three .pointer-bl {
      display: none; }
  .pie-chart .stat__four {
    margin-top: 1rem;
    width: 50%;
    right: -10px; }
    .pie-chart .stat__four .pointer-tr {
      display: none; }
  .pie-chart h3 {
    font-size: 1.625rem;
    margin-top: 1rem; }
  .pie-chart__graphic__wrap {
    padding: 17rem 0 8rem 45%; }
  .pie-chart__graphic img {
    margin-top: -50%;
    width: 100%;
    visibility: visible;
    margin-left: 20px; }
  .pie-chart h3 {
    position: static; }
  .pie-chart .number--medium {
    font-size: 1.625rem;
    line-height: 1; }
  .pie-chart .stat {
    font-size: 1rem; }
  .pie-chart .number__background {
    font-size: 7.5rem; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .section__02__network-description {
    margin: 9rem auto 7rem; }
    .section__02__network-description .stat {
      padding: 0;
      font-size: 1.2rem; } }

@media (min-width: 768px) {
  .section__02 .pie-chart .stat {
    width: 25%;
    position: absolute; }
  .section__02 .pie-chart .stat__two {
    top: 33%; }
  .section__02 .pie-chart .stat__three {
    top: 66%; }
  .section__02 .pie-chart .stat02__one img.chart {
    visibility: hidden; } }

@media (max-width: 767.98px) {
  .section__02__part1 .stat {
    font-size: 1.375rem; }
    .section__02__part1 .stat .number--large {
      font-size: 5.625rem; }
    .section__02__part1 .stat .number__background {
      font-size: 8.125rem;
      line-height: 9rem; }
  .section__02__part1 .stat01__one {
    margin-bottom: 1rem; }
  .section__02 .number--medium {
    line-height: 1.5; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__02 .pie-chart__graphic {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .section__02 .pie-chart h3 {
    font-size: 1.25rem; }
  .section__02 .pie-chart .number--medium {
    font-size: 2.5rem;
    margin-bottom: 0.5rem; }
  .section__02 .pie-chart .stat {
    font-size: 1rem;
    position: absolute;
    padding: 1rem 0; }
  .section__02 .pie-chart .stat__one {
    left: 6%;
    top: -4%; }
  .section__02 .pie-chart .stat__two {
    top: 26%;
    left: -4%; }
  .section__02 .pie-chart .stat__three {
    left: 8%; }
  .section__02 .pie-chart .stat__four {
    left: 78%; }
  .section__02 .pie-chart .pointer-bl {
    right: -2.5rem;
    top: 6px; }
  .section__02 .pie-chart .pointer-tl {
    right: -2rem;
    top: 2rem; }
  .section__02 .pie-chart .pointer-tl {
    right: -2.5rem; }
  .section__02 .pie-chart .pointer-tr {
    left: -48px;
    top: 38px; }
  .section__02 .pie-chart .stat__two .pointer-tl {
    right: -1.5rem; }
  .section__02__part1 .stat {
    font-size: 1.25rem; }
    .section__02__part1 .stat .number--large {
      font-size: 5rem; }
    .section__02__part1 .stat .number__background {
      font-size: 7.5rem;
      line-height: 9rem; }
  .section__02__network-description .number--medium {
    line-height: 2;
    font-size: 4rem; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .section__02 .pie-chart h3 {
    font-size: 1.5rem; }
  .section__02 .pie-chart .number--medium {
    font-size: 3.125rem;
    margin-bottom: 0.75rem; }
  .section__02 .pie-chart .stat {
    font-size: 1.125rem;
    width: 25%;
    position: absolute;
    padding: 1rem; }
  .section__02 .pie-chart .stat__one {
    left: 6%;
    top: -12%; }
  .section__02 .pie-chart .stat__two {
    top: 26%; }
  .section__02 .pie-chart .stat__three {
    left: 8%; }
  .section__02 .pie-chart .stat__four {
    left: 74%; }
  .section__02__part1 .stat {
    font-size: 1.5rem; }
  .section__02__network-description .number--medium {
    font-size: 5rem; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .section__02 .pie-chart .number--medium {
    font-size: 4rem;
    margin-bottom: 0.75rem; }
  .section__02 .pie-chart .stat {
    font-size: 1.125rem;
    padding: 1rem 2rem;
    width: 25%;
    position: absolute; }
  .section__02 .pie-chart .stat__one {
    left: 6%;
    top: -6%;
    width: 30%; }
  .section__02 .pie-chart .stat__three {
    left: 4%; }
  .section__02 .pie-chart .stat__four {
    left: 72%; }
  .section__02 .pie-chart .pointer-tl {
    right: 0rem; } }

@media (min-width: 1440px) {
  .section__02 .pie-chart .number--medium {
    font-size: 5rem; }
  .section__02 .pie-chart .stat {
    font-size: 1.5rem;
    width: 25%;
    position: absolute; }
  .section__02 .pie-chart .stat__one {
    left: 6%;
    width: 30%; }
  .section__02 .pie-chart .stat__two {
    top: 33%;
    left: 0; }
  .section__02 .pie-chart .stat__three {
    top: 66%;
    left: 5%; }
  .section__02 .pie-chart .stat__four {
    left: 66%; }
  .section__02 .pie-chart .pointer-tl {
    right: 0rem; } }

@media screen and (max-width: 420px) {
  .section__02 .pointer-vertical {
    height: 78px;
    left: 60%; }
  .section__02 .pointer-descending {
    height: 48px;
    left: 117%;
    top: 59%; }
  .section__02 .stat__four {
    margin-right: -1rem; }
  .section__02 .pie-chart__graphic {
    margin-bottom: 0rem; } }

.section__03__content .number--large {
  position: unset;
  font-size: 12.5rem;
  color: #005ba6;
  line-height: 12rem; }

.section__03__content__number-description {
  padding: 2rem 0 0 3.5rem; }
  .section__03__content__number-description > div {
    font-size: 1.375rem;
    font-weight: bold;
    color: #005ba6; }
    @media (min-width: 1200px) {
      .section__03__content__number-description > div {
        font-size: 1.75rem; } }
  .section__03__content__number-description p {
    font-size: 1rem;
    font-weight: normal;
    color: #000000; }

@media (min-width: 768px) {
  .section__03__content {
    margin-top: 4rem; } }

.section__03__colour-block {
  margin: 1rem 0 3rem; }

.section__03__three-people-description strong {
  font-family: "Oswald";
  font-weight: 500;
  color: #005ba6; }

.section__03__three-people img {
  width: 100%;
  height: auto; }

.section__03__video {
  margin: 8rem 0; }
  .section__03__video .responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin: 4rem 0; }
  .section__03__video .video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.section__03__map p {
  font-size: 2rem; }

.section__03__map__svg {
  margin: 4rem 0 3rem; }

.section__03__map__countries {
  font-size: 1.375rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10rem; }
  .section__03__map__countries div {
    white-space: nowrap; }
  .section__03__map__countries-wrap {
    padding: 0 1rem; }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .section__03__map__countries {
      font-size: 1.17rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__03__map__countries {
      font-size: 1.25rem; } }
  @media (max-width: 991.98px) {
    .section__03__map__countries {
      font-size: 1.125rem; } }

.dark {
  padding-bottom: 6rem; }
  .dark h3 {
    text-align: center; }

.skills-card-header {
  height: 7rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 3rem 0 1rem;
  justify-content: center;
  display: flex;
  align-items: center; }
  .skills-card-header div {
    align-self: center; }

.skills-card {
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #fff; }
  .skills-card__description {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat;
    background-size: 100.5% auto;
    background-color: #fff;
    font-size: 1.5rem;
    color: #000000;
    flex-grow: 1;
    background-color: #f2f2f2; }
    .skills-card__description p {
      padding: 3rem 1.5rem 3rem; }
      @media (min-width: 992px) and (max-width: 1439.98px) {
        .skills-card__description p {
          font-size: 1.25rem; } }
  .skills-card__colour-block svg {
    width: 100%;
    margin-bottom: -2px; }
  @media (min-width: 1440px) {
    .skills-card {
      padding-right: 1.8rem;
      padding-left: 1.8rem; } }

@media only screen and (max-width: 767px) {
  .section__03__content .stat .number--large {
    font-size: 8rem; }
  .section__03__content__number-description {
    padding: 0; }
    .section__03__content__number-description div {
      text-align: center;
      padding: 0 1rem; }
    .section__03__content__number-description p {
      font-size: 1.2rem;
      text-align: center; }
  .section__03__three-people-description {
    text-align: center; }
    .section__03__three-people-description > div {
      margin-right: 0 !important; }
  .section__03__three-people {
    text-align: center;
    justify-content: center;
    display: flex;
    margin-bottom: 2rem; }
  .section__03__video {
    margin: 0; }
    .section__03__video .container {
      max-width: 100%;
      padding: 0; }
    .section__03__video img {
      display: none; }
    .section__03__video .responsive-video {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      margin: 4rem 0; }
    .section__03__video .video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .section__03 .gbc-background {
    background: none !important; }
  .section__03__map p {
    padding: 0 1rem;
    font-size: 1.3rem; }
  .section__03__map .stat .number--medium {
    font-size: 3rem;
    line-height: 7rem; }
  .section__03__map__svg {
    padding: 0 1rem; }
  .section__03__map__countries {
    font-size: 1.125rem;
    margin-bottom: 7rem; } }
  @media only screen and (max-width: 767px) and (max-width: 390px) {
    .section__03__map__countries {
      font-size: 0.9rem; } }

@media only screen and (max-width: 767px) {
  .section__03 .skills-card-header {
    font-size: 1.7rem;
    margin: 0 0 1rem; }
  .section__03 .skills-card__description {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat;
    background-size: 100% 10px;
    font-size: 1.125rem;
    background-color: #fff; }
  .section__03 .skills-card__colour-block {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .section__03__content .number--large {
    font-size: 8rem; }
  .section__03__content__number-description {
    padding: 3rem 0 0 2rem; }
    .section__03__content__number-description > div {
      font-size: 1.4rem; }
    .section__03__content__number-description p {
      font-size: 1rem; }
  .section__03__colour-block {
    margin: 1rem 0 0; }
    .section__03__colour-block img {
      height: 1rem; }
  .section__03__three-people {
    margin-top: 4rem; }
  .section__03__three-people-description .mr-5 p {
    font-size: 1.3rem; }
  .section__03__map p {
    font-size: 1.5rem; }
  .section__03__map .stat {
    margin: -4rem 0 -2rem; } }

@media only screen and (min-width: 787px) and (max-width: 991px) {
  .section__03 .dark {
    padding-bottom: 6rem; }
  .section__03 .dark-description {
    font-size: 1.3rem !important; }
  .section__03 .skills-card-header {
    font-size: 2.2rem; }
  .section__03 .skills-card__description {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat;
    background-size: 100% 10px;
    background-color: #fff;
    max-height: 200px;
    font-size: 1.5rem; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section__03 .dark {
    padding-bottom: 8rem; }
  .section__03 .dark-description {
    font-size: 1.4rem !important; }
  .section__03 .skills-card-header {
    font-size: 2rem; }
  .section__03 .skills-card__description {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat;
    background-size: 100% auto;
    background-color: #fff;
    font-size: 1.2rem; } }

.section__04__container .number--mild-large {
  margin: 5rem 0; }

.section__04 .stat07__one .number {
  line-height: 1.5; }

.section__04__card-container {
  margin: 6rem 0 6rem; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__04__card-container {
      margin-top: 2rem; } }
  .section__04__card-container > div {
    text-align: center; }
    .section__04__card-container > div svg {
      width: 100%;
      height: auto; }
    .section__04__card-container > div .stat {
      background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat;
      background-size: 100% 10px;
      background-color: #005ba6;
      color: #fff;
      font-size: 2rem;
      margin-top: -8px;
      margin-bottom: 2rem;
      padding: 0;
      height: 100%;
      width: 100%; }
      .section__04__card-container > div .stat .number {
        margin-bottom: -2.5rem;
        line-height: 3; }
      .section__04__card-container > div .stat span {
        padding-bottom: 2rem; }

.section__04__city-container {
  margin: 5rem auto; }
  .section__04__city-container .number {
    line-height: 12rem; }
  .section__04__city-container .stat {
    padding: 0; }
  .section__04__city-container .col-2 {
    margin-right: -1.2rem;
    margin-left: -1rem; }
    .section__04__city-container .col-2 .number {
      margin-bottom: -3rem; }
  .section__04__city-container__num1 {
    z-index: 20;
    padding-right: 3rem;
    padding-bottom: 4rem; }
    .section__04__city-container__num1 p {
      margin-top: 3rem;
      margin-left: 3rem;
      margin-right: 3rem; }
  .section__04__city-container__num2 {
    padding-top: 11rem; }
  .section__04__city-container__num3 {
    padding-top: 5rem; }
  .section__04__city-container__num4 {
    padding-top: 2rem; }
  .section__04__city-container__num5 {
    padding-top: 10rem; }
    .section__04__city-container__num5 p {
      margin: 0;
      font-size: 1.2rem; }
    .section__04__city-container__num5 .stat {
      padding: 1.8rem 1rem 2rem 2rem; }
      .section__04__city-container__num5 .stat .number {
        margin: -4rem auto !important; }
    .section__04__city-container__num5 .circle {
      position: absolute;
      width: 11rem;
      height: 11rem;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 6px solid #394a50; }
  .section__04__city-container__toronto-line {
    width: 100%;
    height: 100%;
    margin-left: -14rem;
    object-fit: contain;
    transform: scale(1.4, 1.2);
    transform-origin: 0 0;
    margin-top: -37rem; }
    .section__04__city-container__toronto-line img {
      width: 100%;
      height: 700px; }
      @media (min-width: 1200px) and (max-width: 1439.98px) {
        .section__04__city-container__toronto-line img {
          width: 96%; } }
  .section__04__city-container__arrow-container p {
    margin: -2.5rem 0 4rem; }

.gbc-background-cityline {
  background-position: top right !important;
  position: relative; }

.city-line {
  height: 50%;
  background: url(../images/common_ui/gbc-pattern-sized.svg) repeat #f2f2f2;
  z-index: 10;
  background-size: 232px;
  position: absolute;
  right: 0;
  background-position-x: right;
  top: 154px;
  background-position-y: -155px; }
  @media (max-width: 767.98px) {
    .city-line {
      display: none; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .city-line {
      height: 70%; } }

.research-areas {
  margin: 6rem 0; }
  @media (min-width: 1200px) {
    .research-areas .container {
      padding: 0 90px 0 74px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .research-areas .container {
      padding: 0 55px 0 39px; } }
  @media (min-width: 992px) {
    .research-areas__column-one {
      right: -2rem; } }
  @media (min-width: 992px) {
    .research-areas__column-two .research-areas__item-one {
      right: -1rem; } }
  @media (min-width: 992px) {
    .research-areas__column-three {
      left: -1rem; } }
  .research-areas__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background-color: rgba(0, 91, 166, 0.6); }
    .research-areas__item__title {
      position: absolute;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.27;
      text-align: center;
      color: #ffffff;
      padding: 1rem; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .research-areas__item__title {
          font-size: 1.4rem; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .research-areas__item {
        margin-bottom: 0.7rem; } }
  .research-areas__number .stat {
    margin-left: 1rem;
    margin-top: -2rem; }
  .research-areas__number__description {
    font-size: 22px;
    line-height: 1.91;
    color: #000; }
  @media (min-width: 1200px) {
    .research-areas__number .stat .number--extra-large {
      font-size: 22.5rem;
      line-height: 1; } }
  @media (min-width: 992px) {
    .research-areas__number .stat {
      margin-left: 3rem;
      margin-top: -3rem; }
    .research-areas__number__description {
      top: -1rem;
      left: 2rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .research-areas__number .stat .number--extra-large {
      font-size: 13rem; }
    .research-areas__number__description {
      font-size: 19px; } }
  @media (max-width: 767.98px) {
    .research-areas__number .stat .number--extra-large {
      font-size: 12.5rem;
      line-height: 12rem; } }
  .research-areas .last-row-images .research-areas__item {
    margin-right: 1rem; }
  .research-areas .last-row-images :last-child {
    margin-right: 0; }

@media only screen and (max-width: 489px) {
  .section__04__city-container__arrow-container {
    margin-top: 6.5rem !important; } }

@media only screen and (min-width: 490px) and (max-width: 530px) {
  .section__04__city-container__arrow-container {
    margin-top: 8rem !important; } }

@media only screen and (min-width: 531px) and (max-width: 650px) {
  .section__04__city-container__arrow-container {
    margin-top: 9rem !important; } }

@media only screen and (min-width: 651px) and (max-width: 767px) {
  .section__04__city-container__arrow-container {
    margin-top: 10rem !important; } }

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .section__04__city-container__toronto-line {
    margin-left: -7rem !important;
    -webkit-transform: scale(1.8, 1.8) !important;
    transform: scale(1.8, 1.8) !important;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; } }

@media only screen and (max-width: 767px) {
  .section__04__container .number--mild-large {
    margin: 0; }
  .section__04__card-container {
    margin: 3rem 0 3rem; }
    .section__04__card-container > div {
      text-align: center;
      margin-bottom: 3rem; }
      .section__04__card-container > div svg {
        width: 100%;
        height: auto; }
      .section__04__card-container > div .stat {
        background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat top center;
        background-size: 100% 10px;
        font-size: 1.375rem;
        background-color: #005ba6; }
        .section__04__card-container > div .stat .number {
          margin-bottom: -2.5rem;
          font-size: 2.5rem;
          line-height: 8rem; }
          .section__04__card-container > div .stat .number--small {
            font-size: 2.5rem; }
  .section__04__city-container {
    padding: 0 5rem; }
    .section__04__city-container p {
      font-size: 1.125rem; }
    .section__04__city-container__num1 {
      padding: 0; }
      .section__04__city-container__num1 p {
        margin-top: -1rem;
        margin-left: 3rem;
        margin-right: 3rem; }
      .section__04__city-container__num1 .number--mild-large {
        font-size: 7rem; }
    .section__04__city-container__num2 {
      padding: 0; }
      .section__04__city-container__num2 p {
        margin-top: -3.5rem; }
    .section__04__city-container__num3 {
      padding: 0; }
      .section__04__city-container__num3 p {
        margin-top: -3.5rem; }
    .section__04__city-container__num4 {
      padding-top: 0; }
      .section__04__city-container__num4 p {
        margin-top: -3.5rem; }
    .section__04__city-container__num5 {
      padding-top: 3rem;
      justify-content: center;
      display: flex; }
      .section__04__city-container__num5 .circle {
        margin-top: -1rem;
        margin-left: -1rem; }
      .section__04__city-container__num5 .stat {
        padding: 1.8rem 2rem 2rem; }
    .section__04__city-container__toronto-line {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-left: -4.2rem;
      -webkit-transform: scale(1.7, 1.8);
      transform: scale(1.7, 1.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      margin-top: 2rem; }
      .section__04__city-container__toronto-line img {
        height: 174px; }
    .section__04__city-container__arrow-container {
      margin-top: 7.5rem; }
      .section__04__city-container__arrow-container img {
        width: 3rem;
        height: 7rem; }
      .section__04__city-container__arrow-container__number {
        margin-top: -3rem !important; }
    .section__04__city-container__arrow-container + p {
      margin-bottom: 3rem; }
  .research-areas {
    margin: 6rem 0; }
    .research-areas__number__description {
      font-size: 1.25rem;
      line-height: 1.75;
      margin: 2rem 0 4rem;
      text-align: center; }
    .research-areas__item {
      margin-bottom: 3rem; }
      .research-areas__item__title {
        font-size: 1.875rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section__04__container {
    max-width: 90%; }
    .section__04__container .number--mild-large {
      margin: 1rem 0 1rem;
      font-size: 7rem; }
  .section__04__card-container > div {
    margin-bottom: 3rem; }
  .section__04__card-container .number--small {
    font-size: 3rem; }
  .section__04__card-container span {
    font-size: 1.3rem; }
  .research-areas {
    margin: 6rem 0; }
    .research-areas__number {
      margin-bottom: 3rem; }
      .research-areas__number .number--extra-large {
        font-size: 15rem; }
      .research-areas__number__description {
        font-size: 1.3rem; }
    .research-areas__item {
      margin-bottom: 3rem; }
      .research-areas__item__title {
        font-size: 1.5rem; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__04__city-container p {
    font-size: 1.2rem;
    text-align: center; }
  .section__04__city-container__num1 {
    padding-right: 0;
    padding-bottom: 6rem; }
    .section__04__city-container__num1 .number--mild-large {
      font-size: 9rem; }
    .section__04__city-container__num1 p {
      margin: 0rem 0rem 2rem;
      padding: 0 1rem;
      font-size: 1.4rem; }
  .section__04__city-container__num2 {
    padding-top: 0rem; }
    .section__04__city-container__num2 .number {
      line-height: 6rem; }
    .section__04__city-container__num2 .stat-one {
      margin-bottom: 5rem; }
  .section__04__city-container__num3 {
    padding-top: 1rem; }
    .section__04__city-container__num3 .number {
      line-height: 4rem; }
    .section__04__city-container__num3 .stat-one {
      margin-bottom: 4rem; }
    .section__04__city-container__num3 .circle {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 10rem;
      height: 10rem;
      border: 4px solid #394a50;
      margin-left: 2rem; }
      .section__04__city-container__num3 .circle .stat-two p:first-child {
        margin-bottom: 0;
        margin-top: 1rem; }
    .section__04__city-container__num3 p {
      padding: 0 2rem; }
  .section__04__city-container__toronto-line {
    -webkit-transform: scale(1.5, 1.7);
    transform: scale(1.5, 1.7);
    margin-top: -38rem;
    margin-left: -9rem; }
  .section__04__city-container__arrow-container {
    margin-top: 7rem; }
    .section__04__city-container__arrow-container__number {
      margin-top: -1rem; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .section__04__city-container p {
    font-size: 1.2rem; }
  .section__04__city-container__num1 {
    right: 1rem;
    padding-bottom: 6rem; }
    .section__04__city-container__num1 .number--mild-large {
      font-size: 10.5rem; }
    .section__04__city-container__num1 p {
      margin: 0rem 0.5rem 2rem; }
  .section__04__city-container__num2 {
    left: 1rem;
    padding-top: 9rem; }
    .section__04__city-container__num2 .number {
      line-height: 6rem; }
  .section__04__city-container__num3 {
    padding-left: 0;
    padding-right: 0;
    padding-top: 4rem; }
    .section__04__city-container__num3 .number {
      line-height: 6rem; }
  .section__04__city-container__num4 {
    left: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem; }
    .section__04__city-container__num4 .number {
      line-height: 6rem; }
    .section__04__city-container__num4 p {
      padding: 0 1rem; }
  .section__04__city-container__num5 {
    left: 1rem;
    padding-top: 6rem; }
    .section__04__city-container__num5 .circle {
      width: 10rem;
      height: 10rem;
      border: 4px solid #394a50; }
    .section__04__city-container__num5 .stat {
      padding: 1rem 1rem 3rem 1rem; }
  .section__04__city-container__toronto-line {
    -webkit-transform: scale(1.7, 1.8);
    transform: scale(1.7, 1.8);
    margin-top: -50rem;
    margin-left: -16rem; }
  .section__04__city-container__arrow-container {
    margin-top: 12rem; }
    .section__04__city-container__arrow-container__number {
      margin-top: -1rem; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .section__04__city-container__num1 {
    padding-bottom: 6rem;
    right: 1rem; }
    .section__04__city-container__num1 p {
      margin: 2rem 0.5rem; }
  .section__04__city-container__num2 {
    left: 2rem;
    padding-top: 12rem; }
    .section__04__city-container__num2 .number {
      line-height: 6rem; }
  .section__04__city-container__num3 {
    padding-top: 6rem; }
    .section__04__city-container__num3 .number {
      line-height: 6rem; }
  .section__04__city-container__num4 {
    left: 1rem;
    padding-top: 1rem; }
    .section__04__city-container__num4 .number {
      line-height: 6rem; }
  .section__04__city-container__num5 {
    padding-top: 7rem; }
    .section__04__city-container__num5 .stat {
      padding: 1.2rem 1rem 3rem 1rem; }
  .section__04__city-container__toronto-line {
    -webkit-transform: scale(1.77, 1.8);
    transform: scale(1.77, 1.8);
    margin-top: -52rem;
    margin-left: -20rem; }
  .section__04__city-container__arrow-container {
    margin-top: 16rem; }
    .section__04__city-container__arrow-container__number {
      margin-top: -1rem; } }

@media (min-width: 1440px) {
  .section__04__city-container .number--mild-large {
    font-size: 14rem; }
  .section__04__city-container .number--extra-small {
    font-size: 5rem; }
  .section__04__city-container p {
    font-size: 1.6rem; }
  .section__04__city-container__num1 {
    padding-bottom: 6rem; }
    .section__04__city-container__num1 p {
      margin: 2rem 0.5rem; }
  .section__04__city-container__num2 {
    left: 2rem;
    padding-top: 13rem; }
    .section__04__city-container__num2 .number {
      line-height: 6rem; }
  .section__04__city-container__num3 {
    padding-top: 6rem; }
    .section__04__city-container__num3 .number {
      line-height: 6rem; }
  .section__04__city-container__num4 {
    right: 1rem;
    padding-top: 0rem; }
    .section__04__city-container__num4 .number {
      line-height: 6rem; }
  .section__04__city-container__num5 {
    padding-top: 7rem;
    right: 2rem; }
    .section__04__city-container__num5 .stat {
      padding: 1.2rem 1rem 3rem 1rem; }
    .section__04__city-container__num5 .circle {
      width: 12rem;
      height: 12rem; }
  .section__04__city-container__toronto-line {
    -webkit-transform: scale(1.6, 1.8);
    transform: scale(1.6, 1.8);
    margin-top: -50rem;
    margin-left: -20rem; }
  .section__04__city-container__arrow-container {
    margin-top: 19rem; }
    .section__04__city-container__arrow-container__number {
      margin-top: -1rem; } }

@media only screen and (min-width: 1900px) {
  .section__04__city-container__num3 {
    left: 3rem;
    padding-top: 6rem; }
    .section__04__city-container__num3 .number {
      line-height: 6rem; }
  .section__04__city-container__num4 {
    left: 2rem;
    padding-top: 0rem; }
    .section__04__city-container__num4 .number {
      line-height: 6rem; }
  .section__04__city-container__num5 {
    padding-top: 7rem;
    left: 4rem; }
  .section__04__city-container__toronto-line {
    -webkit-transform: scale(1.7, 1.8);
    transform: scale(1.7, 1.8);
    margin-top: -50rem;
    margin-left: -21rem; } }

.section__05__part1 {
  margin: 6rem 0; }
  .section__05__part1__number--large {
    font-size: 16rem; }
  .section__05__part1__number__background {
    font-size: 25rem; }
  .section__05__part1__button {
    margin-left: 3rem; }
    .section__05__part1__button a {
      width: 16rem;
      height: 4.25rem;
      font-size: 1.2rem;
      background-color: #005ba6;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
      border-radius: 0;
      -webkit-transition-property: none;
      transition-property: none;
      position: relative;
      margin-left: -3rem; }
      .section__05__part1__button a:hover {
        background-color: #004e84;
        color: #fff; }
    .section__05__part1__button a::after {
      content: "";
      background: url("/assets/images/05_fostering-entrepreneurship/button-arrow.svg") no-repeat center;
      background-size: 45%;
      width: 3rem;
      height: 4.25rem;
      right: -2.95rem;
      position: absolute;
      background-color: #2aa9e0; }
    .section__05__part1__button a:hover:after {
      background-color: #1B7BAB; }
  .section__05__part1 .stat10__one__paragraph {
    margin-top: -1rem;
    z-index: 99;
    position: relative; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .section__05__part1 .stat10__one__paragraph {
        margin-top: 0; } }
  @media (min-width: 992px) {
    .section__05__part1 .stat10__one {
      padding: 1rem 0; }
      .section__05__part1 .stat10__one .number--large {
        font-size: 14rem; }
      .section__05__part1 .stat10__one .number__background {
        font-size: 22rem;
        line-height: 1; } }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .section__05__part1__button a {
      position: relative; }
    .section__05__part1__button a::after {
      top: -0.05rem;
      right: -2.95rem; } } }

.section__05__part2 {
  margin: 10rem auto; }
  .section__05__part2 p {
    font-size: 2.5rem; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .section__05__part2 p {
        font-size: 2rem; } }
    .section__05__part2 p span {
      font-family: "Oswald";
      font-weight: bold;
      color: #005ba6; }
  .section__05__part2__revenue div:first-child {
    text-align: left;
    font-size: 2rem; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .section__05__part2__revenue div:first-child {
        font-size: 1.75rem; } }
  .section__05__part2__revenue__money {
    font-family: "Oswald";
    font-size: 7rem;
    color: #005ba6; }
    .section__05__part2__revenue__money .footnote {
      vertical-align: text-top;
      padding-top: 3.5rem;
      margin-left: -2px;
      display: inline-block; }

.section__05__part3 .row {
  margin: 7rem auto;
  display: flex;
  align-items: center; }

.section__05__part3 .colour-bar-small {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg) scale(0.4, 1);
  position: absolute;
  left: 0; }

.section__05__part3 .colour-bar-small-bg {
  background: url("/assets/images/common_ui/colour-bar-small.png") no-repeat left;
  background-size: 8px 80%;
  padding: 3rem;
  margin-left: 0;
  display: flex;
  align-content: center; }

.section__05__part3__description {
  font-size: 1.6rem;
  position: relative;
  overflow: hidden;
  height: 12rem;
  align-items: center; }
  .section__05__part3__description p {
    position: absolute;
    left: 1rem;
    margin-left: 2rem;
    z-index: 10;
    margin-bottom: 0; }

.section__05__part3 :last-child .stat {
  margin-right: -4rem; }

@media only screen and (max-width: 767px) {
  .section__05__part1 {
    margin: 5rem 0; }
    .section__05__part1 .stat {
      font-size: 1.375rem;
      margin-top: -1rem; }
      .section__05__part1 .stat .number--large {
        font-size: 10rem; }
      .section__05__part1 .stat .number__background {
        font-size: 16rem; }
    .section__05__part1__button {
      margin-left: 0;
      justify-content: center;
      display: flex; }
      .section__05__part1__button a {
        width: 12rem;
        height: 3.68rem;
        font-size: 1rem; }
      .section__05__part1__button a::after {
        content: "";
        background: url("/assets/images/05_fostering-entrepreneurship/button-arrow.svg") no-repeat center;
        display: inline-block;
        width: 3rem;
        height: 3.68rem;
        right: -2.95rem;
        position: absolute;
        background-color: #2aa9e0; }
  .section__05__part2 {
    margin: 5rem auto; }
    .section__05__part2 p {
      font-size: 1.5rem;
      text-align: center !important;
      padding: 0;
      margin-bottom: 0.5rem; }
      .section__05__part2 p span {
        font-size: 1.75rem; }
    .section__05__part2 img {
      width: 10rem;
      height: 9rem; }
    .section__05__part2__revenue {
      text-align: center; }
      .section__05__part2__revenue div:first-child {
        font-size: 1.5rem;
        text-align: center; }
      .section__05__part2__revenue__money {
        font-size: 3.5rem; }
        .section__05__part2__revenue__money .footnote {
          vertical-align: super;
          font-size: 0.1em;
          margin-left: -2px; }
  .section__05__part3 .row {
    margin: 3rem auto; }
  .section__05__part3 .stat {
    padding: 0; }
  .section__05__part3 .colour-bar-small-bg {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat top center;
    background-size: 100% 7px;
    padding: 2rem 0 0 0;
    margin-left: 0;
    text-align: center; }
  .section__05__part3__description {
    height: auto;
    font-size: 1.125rem; }
    .section__05__part3__description p {
      position: static;
      height: auto;
      margin: 0; }
  .section__05__part3 :last-child .stat {
    margin-right: 0; }
  .section__05__part3 .number__background {
    font-size: 8rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section__05__part1 .stat {
    padding: 1rem 0;
    font-size: 1.1rem; }
    .section__05__part1 .stat .number--large {
      font-size: 8rem; }
    .section__05__part1 .stat .number__background {
      font-size: 15rem; }
  .section__05__part1__button a {
    height: 3.25rem;
    width: 12rem;
    font-size: 1rem; }
  .section__05__part1__button a::after {
    height: 3.25rem; }
  .section__05__part2 p {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  .section__05__part2 .colour-block--one img {
    height: 1rem; }
  .section__05__part2__revenue div:first-child {
    font-size: 1.25rem; }
  .section__05__part2__revenue__money {
    font-size: 5rem; }
    .section__05__part2__revenue__money .footnote {
      padding-top: 2.5rem; }
  .section__05__part3 .number__background {
    font-size: 9rem; }
  .section__05__part3 .colour-bar-small-bg {
    font-size: 1.4rem; }
  .section__05__part3__description p {
    left: 1rem;
    margin-left: 2rem;
    z-index: 10; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .section__05__part3 .number__background {
    z-index: -5; }
  .section__05__part3__description p {
    left: 1rem;
    max-width: auto !important;
    margin-left: 2rem;
    z-index: -2; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__05__part3 .number__one {
    margin-right: -1rem; }
  .section__05__part3 .number__three {
    margin-right: -5rem; } }

@media (min-width: 992px) {
  .section__05__part3 .number__one {
    margin-right: -2rem; }
  .section__05__part3 .number__three {
    margin-right: -8rem; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .section__05__part3 .colour-bar-small-bg {
    background-position-x: 12px; } }

.section__06__part1 > div {
  margin: 8rem 0; }

.section__06__part1__description {
  margin-top: 2rem;
  font-size: 1.3rem; }

.section__06__part1 .number--large {
  position: static; }

.section__06__part2 {
  font-size: 1.75rem; }
  .section__06__part2 .number--mild-large {
    line-height: 12rem; }
  .section__06__part2 .office-link {
    font-weight: bold; }
    .section__06__part2 .office-link:hover, .section__06__part2 .office-link:focus {
      text-decoration: underline; }
  .section__06__part2 .row {
    margin-bottom: 2.5rem; }
  @media (max-width: 767.98px) {
    .section__06__part2 .fade-in-sec6-01 {
      margin-bottom: 1rem; }
    .section__06__part2 .row {
      margin-bottom: 0; } }
  @media (min-width: 768px) {
    .section__06__part2__list .multiline {
      margin-left: 3.5rem;
      margin-top: -2rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__06__part2 {
      font-size: 1rem; }
      .section__06__part2 .number {
        line-height: 1.5; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__06__part2 {
      font-size: 1.5rem; } }

.section__06__part3 {
  margin: 13rem auto; }
  .section__06__part3 img {
    width: 16.75rem;
    height: 26rem; }
  .section__06__part3 .stat {
    padding: 1rem 0; }

.section__06__part4 .number--small {
  font-size: 3rem; }

.section__06__part4 h3 {
  font-size: 3.75rem;
  font-weight: 500;
  text-align: center;
  color: #283248;
  margin-bottom: 3rem;
  text-transform: uppercase; }

.section__06__part4__card {
  margin-bottom: 8rem; }
  .section__06__part4__card svg {
    width: 100%; }

.section__06__part4 .stat-container {
  background: url("/assets/images/common_ui/colour-bar-small-horizontal.png") no-repeat;
  background-size: 100% 10px;
  padding-top: 1rem;
  width: 100%; }

.section__06__part4 .stat {
  background: url("/assets/images/common_ui/flag.svg") no-repeat bottom #005ba6;
  background-size: 105% auto;
  color: #fff;
  margin-top: -0.4rem;
  padding: 3rem 4rem 8rem;
  min-height: 33rem;
  background-position-y: calc(100% + 1px); }
  .section__06__part4 .stat p {
    margin: 0; }
  .section__06__part4 .stat a {
    vertical-align: super;
    font-size: 1rem;
    padding-left: 2px;
    color: white; }

@media only screen and (min-width: 600px) and (max-width: 650px) {
  .section__06__part1 {
    padding: 0 100px !important; }
    .section__06__part1 img {
      margin-left: 20% !important; } }

@media only screen and (min-width: 651px) and (max-width: 767px) {
  .section__06__part1 {
    padding: 0 120px !important; }
    .section__06__part1 img {
      margin-left: 20% !important; } }

@media only screen and (max-width: 767px) {
  .section__06__part1__globe {
    width: 6rem; }
  .section__06__part1__description {
    margin-top: 0;
    text-align: center; }
  .section__06__part1 img {
    margin: 1rem;
    margin-left: 20% !important; }
  .section__06__part2 {
    font-size: 1.25rem; }
    .section__06__part2 .fade-in-sec6-01 img {
      width: 1.5rem; }
    .section__06__part2 .multiline {
      margin-left: 2.5rem;
      margin-top: -1.875rem; }
    .section__06__part2__description {
      text-align: center;
      margin-top: -2rem;
      font-size: 1.375rem; }
  .section__06__part3 {
    margin: 8rem auto; }
    .section__06__part3 img {
      width: 14rem;
      height: 20rem;
      margin-left: 2.5rem; }
    .section__06__part3 .number {
      margin-top: 3rem; }
      .section__06__part3 .number--large {
        font-size: 6rem; }
      .section__06__part3 .number .number__background {
        font-size: 9.5rem;
        line-height: 1; }
    .section__06__part3 .stat {
      font-size: 1.375rem; }
  .section__06__part4 h3 {
    font-size: 2.25rem; }
  .section__06__part4__card h3 {
    font-size: 3rem; }
  .section__06__part4__card .stat {
    min-height: 20rem;
    padding-left: 1rem;
    padding-right: 1rem; }
    .section__06__part4__card .stat .number {
      justify-content: center; }
      .section__06__part4__card .stat .number--small {
        font-size: 2.25rem; }
  .section__06__part4__card > div {
    margin-bottom: 2rem; }
  .section__06__part4__card div:last-child .stat {
    padding-bottom: 8rem; }
  .section__06__part4__description {
    margin: -6rem auto 2rem;
    max-width: 75%;
    text-align: center; }
  a.btn-pdf {
    margin-bottom: 3rem;
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section__06__part1__description {
    font-size: 1.25rem; }
  .section__06__part1 img {
    max-height: 8rem; }
  .section__06__part2 {
    font-size: 1.125rem; }
    .section__06__part2__description {
      font-size: 1.25rem; }
  .section__06__part3 img {
    height: 23rem; }
  .section__06__part3 .stat {
    font-size: 1.3rem; }
    .section__06__part3 .stat .number--large {
      font-size: 7rem; }
  .section__06__part4 .number--small {
    font-size: 2.5rem; }
  .section__06__part4__card .stat {
    min-height: 22rem;
    padding: 3rem 2rem; }
  .section__06__part4 h3 {
    font-size: 2.5rem; }
  .btn-pdf {
    font-size: 1.3rem; }
    .btn-pdf svg {
      width: 40px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section__06__part4 h3 {
    font-size: 2.75rem; }
  .section__06__part4__card .stat {
    min-height: 23rem; }
    .section__06__part4__card .stat .number--small {
      font-size: 3rem; }
  .section__06__part4__card div {
    padding-left: 0; }
  .section__06__part4__card div:last-child .stat {
    padding-bottom: 7rem; } }

@media (min-width: 1200px) {
  .section__06__part4__card .stat {
    min-height: 30rem; }
    .section__06__part4__card .stat .number--small {
      font-size: 4.5rem; } }

@media (min-width: 1440px) {
  .section__06__part4__card .stat16__one {
    margin-left: 4rem !important; }
  .section__06__part4__card .stat16__two {
    margin-right: 4rem !important; } }

.section__07 .container {
  overflow: hidden; }

.section__07 .number {
  width: 100%; }

.section__07 .footnote {
  font-size: 10px !important; }

.section__07 .stat {
  display: flex;
  justify-content: center;
  align-items: center; }

.section__07 .number--large {
  position: static; }

.section__07 .number__background {
  font-size: 9rem;
  letter-spacing: 0.5rem; }

.section__07__colour-seperator {
  width: 0.6rem;
  height: 9rem;
  position: absolute; }

.section__07__description {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 25rem; }
  .section__07__description p {
    text-align: start;
    font-size: 1rem;
    margin-top: 1.5rem; }
    .section__07__description p .link {
      font-weight: bold; }
      .section__07__description p .link:hover, .section__07__description p .link:focus {
        text-decoration: underline; }

.section__07__part1 {
  margin: 10rem 0; }
  .section__07__part1__stat1 {
    display: flex;
    flex-direction: row; }
    .section__07__part1__stat1 .number--large {
      color: #11a1dc; }
    .section__07__part1__stat1 .section__07__colour-seperator {
      background-color: #11a1dc; }
    .section__07__part1__stat1 .section__07__description {
      left: 25rem; }
  .section__07__part1__stat2 {
    display: flex;
    flex-direction: row; }
    .section__07__part1__stat2 .number--large {
      color: #7677b8; }
    .section__07__part1__stat2__colour-seperator {
      background-color: #7677b8; }

.section__07__part2__stat1 {
  display: flex;
  flex-direction: row; }
  .section__07__part2__stat1 .number--large {
    color: #fdcc09;
    left: -4rem; }
  .section__07__part2__stat1__colour-seperator {
    background-color: #fdcc09; }

.section__07__part3 {
  margin: 10rem 0; }
  .section__07__part3__stat1 {
    display: flex;
    flex-direction: row; }
    .section__07__part3__stat1 .number--large {
      color: #7677b8; }
    .section__07__part3__stat1__colour-seperator {
      background-color: #7677b8; }
    .section__07__part3__stat1 .section__07__description {
      left: 25rem; }
  .section__07__part3__stat2 {
    display: flex;
    flex-direction: row; }
    .section__07__part3__stat2 .number--large {
      color: #f36e29; }
    .section__07__part3__stat2__colour-seperator {
      background-color: #f36e29; }

.section__07__part4 {
  padding-bottom: 0; }
  .section__07__part4__header {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #06112f; }
    .section__07__part4__header p {
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      margin: 0;
      letter-spacing: 2px; }
    .section__07__part4__header h3 {
      font-family: "OpenSans", "Open Sans", sans-serif;
      font-size: 2.5rem;
      font-weight: bold; }
  .section__07__part4__para-container {
    background: url("/assets/images/07_helping-neighbourhoods-thrive/Daniels-283248.jpg") no-repeat bottom right;
    background-size: auto 100%; }
  .section__07__part4__para {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 0; }
    .section__07__part4__para a {
      font-weight: bold;
      color: #fff; }
      .section__07__part4__para a:hover, .section__07__part4__para a:focus {
        text-decoration: underline; }
  .section__07__part4 img {
    width: 40rem;
    height: 41rem; }

.section__07__part5 {
  border-top: 3px solid white;
  background: url("/assets/images/07_helping-neighbourhoods-thrive/TheArbour-283248.jpg") no-repeat bottom left;
  background-size: 35% auto; }
  .section__07__part5__content {
    padding: 6rem 3rem 0rem; }
    .section__07__part5__content a {
      font-weight: bold;
      color: #fff; }
      .section__07__part5__content a:hover, .section__07__part5__content a:focus {
        text-decoration: underline; }
  .section__07__part5__ribbon {
    margin: 2rem 0; }
    .section__07__part5__ribbon img {
      width: 3rem;
      height: 5rem; }
    .section__07__part5__ribbon__description {
      position: relative;
      overflow: hidden;
      padding: 3.5rem 0 3rem 3rem;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      @media screen and (min-width: 992px) {
        .section__07__part5__ribbon__description {
          padding: 3.5rem 0 3.5rem 3rem; } }
      .section__07__part5__ribbon__description p {
        font-weight: bold;
        position: absolute;
        display: block; }

@media only screen and (max-width: 767px) {
  .section__07 .number {
    text-align: center; }
    .section__07 .number--large {
      font-size: 5rem;
      left: unset; }
    .section__07 .number__background {
      font-size: 6.875rem; }
  .section__07 .stat {
    flex-direction: column;
    padding: 4rem 0; }
  .section__07__description {
    position: static;
    margin-top: 2rem; }
    .section__07__description p {
      font-size: 1.25rem;
      text-align: center; }
  .section__07 .section__07__colour-seperator {
    top: 9rem;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg) scale(0.8, 2.5); }
  .section__07__part1 {
    margin: 5rem 0; }
    .section__07__part1__stat1 {
      display: flex;
      flex-direction: row; }
      .section__07__part1__stat1 .number--large {
        color: #11a1dc; }
  .section__07__part2__stat1 {
    margin-top: -4rem; }
    .section__07__part2__stat1 .number__background {
      font-size: 8rem; }
  .section__07__part3 {
    margin: 0; }
  .section__07__part4__header h3 {
    font-size: 2rem;
    text-align: center; }
  .section__07__part4__para-container {
    display: flex;
    background: url("/assets/images/07_helping-neighbourhoods-thrive/Daniels-06112f.jpg") no-repeat bottom right;
    background-size: 100% auto;
    background-color: #06112f;
    height: 20rem; }
    .section__07__part4__para-container p {
      visibility: hidden; }
  .section__07__part4__para {
    font-size: 1.25rem;
    text-align: center; }
  .section__07__part5 {
    background: none;
    border-top: 0;
    padding-bottom: 0; }
    .section__07__part5__content {
      padding: 3rem 1rem;
      text-align: center; }
    .section__07__part5__ribbon img {
      width: 5rem;
      height: 5rem;
      margin-bottom: 2rem; }
    .section__07__part5__arbour-image-container {
      background: url("/assets/images/07_helping-neighbourhoods-thrive/TheArbour-06112f.jpg") no-repeat;
      background-size: auto 100%;
      background-position: left;
      height: 38rem;
      background-color: #06112f; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__07__part4 p {
    font-size: 1rem; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .section__07__description {
    height: 100%; }
  .section__07 .stat .number {
    width: 100%; }
  .section__07__part1 {
    margin: 5rem 0; }
    .section__07__part1__stat1 {
      margin-bottom: 3rem; }
      .section__07__part1__stat1__colour-seperator {
        left: 15rem; }
    .section__07__part1__stat2__colour-seperator {
      left: 15rem; }
    .section__07__part1__stat2 .section__07__description {
      left: 24rem; }
  .section__07__part2__stat1 {
    margin-top: 0; }
    .section__07__part2__stat1 .number--large {
      left: -3rem; }
    .section__07__part2__stat1__colour-seperator {
      left: 16rem; }
    .section__07__part2__stat1 .section__07__description {
      left: 25rem; }
  .section__07__part3 {
    margin: 5rem 0; }
    .section__07__part3__stat1 {
      margin-bottom: 3rem; }
      .section__07__part3__stat1__colour-seperator {
        right: 14rem; }
    .section__07__part3__stat2__colour-seperator {
      right: 14rem; }
    .section__07__part3__stat2 .section__07__description {
      left: 24rem; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section__07__part1 .section__07__description {
    left: 27rem; }
  .section__07__part1__stat1 {
    margin-bottom: 3rem; }
    .section__07__part1__stat1__colour-seperator {
      left: 20rem; }
  .section__07__part1__stat2__colour-seperator {
    left: 20rem; }
  .section__07__part1__stat2 .section__07__description {
    left: 27rem; }
  .section__07__part2__stat1 {
    margin-top: 0; }
    .section__07__part2__stat1 .number--large {
      left: -6rem; }
    .section__07__part2__stat1__colour-seperator {
      left: 21rem; }
    .section__07__part2__stat1 .section__07__description {
      left: 28rem; }
  .section__07__part3 {
    margin: 5rem 0; }
    .section__07__part3__stat1 {
      margin-bottom: 3rem; }
      .section__07__part3__stat1__colour-seperator {
        right: unset; }
      .section__07__part3__stat1 .section__07__description {
        left: 27rem; }
    .section__07__part3__stat2__colour-seperator {
      right: unset; }
    .section__07__part3__stat2 .section__07__description {
      left: 27rem; } }

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section__07 .section__07__description {
    left: 22rem; }
  .section__07__part2__stat1 {
    margin-top: 0; }
    .section__07__part2__stat1 .section__07__description {
      left: 31rem; } }

@media (min-width: 1440px) {
  .section__07__colour-seperator {
    right: 10rem; }
  .section__07__part2__stat1 {
    display: flex;
    flex-direction: row; }
    .section__07__part2__stat1 .number--large {
      color: #fdcc09;
      left: -4rem; }
    .section__07__part2__stat1__colour-seperator {
      background-color: #fdcc09;
      right: 30rem; }
    .section__07__part2__stat1 .section__07__description {
      left: 37rem; } }

/*
 * WIP
 * Will need to clean up the absolute positioning approach above.
 * Goal: adjust between breakpoints fluidly.
 */
.section__07__item {
  padding: 4rem 0; }
  .section__07__item__background {
    position: absolute !important; }
  .section__07__item__description p {
    font-size: 1rem;
    margin-top: 1.5rem; }
    .section__07__item__description p .link {
      font-weight: bold; }
      .section__07__item__description p .link:hover, .section__07__item__description p .link:focus {
        text-decoration: underline; }

.section__07 .number .number--large {
  justify-content: flex-end; }

.section__07 .stat:after {
  content: '';
  height: 80%;
  width: 10px;
  position: absolute;
  right: 1.75rem;
  top: 10%; }

.section__07 .stat17__one .number--large {
  color: #11a1dc; }

.section__07 .stat17__one .stat:after {
  background-color: #11a1dc; }

.section__07 .stat17__two .number--large {
  color: #7677b8; }

.section__07 .stat17__two .stat:after {
  background-color: #7677b8; }

.section__07 .stat17__three .number--large {
  color: #fdcc09; }

.section__07 .stat17__three .stat:after {
  background-color: #fdcc09; }

.section__07 .stat17__four .number--large {
  color: #7677b8; }

.section__07 .stat17__four .stat:after {
  background-color: #7677b8; }

.section__07 .stat17__five .number--large {
  color: #f36e29; }

.section__07 .stat17__five .stat:after {
  background-color: #f36e29; }

@media (max-width: 767.98px) {
  .section__07__item {
    padding: 0 0 3rem; }
    .section__07__item__background {
      align-self: flex-start;
      margin-top: 1.5rem; }
    .section__07__item__description p {
      font-size: 1.25rem;
      text-align: center; }
    .section__07__item__description a {
      font-weight: 700; }
  .section__07__part5__ribbon__description {
    padding: 0;
    overflow: unset; }
    .section__07__part5__ribbon__description p {
      position: static; }
  .section__07 .number .number--large {
    justify-content: center; }
  .section__07 .stat:after {
    content: '';
    height: 8px;
    width: 80%;
    position: absolute;
    right: 10%;
    top: unset;
    bottom: 10%; } }

@media (min-width: 768px) {
  .section__07__part5__ribbon__content {
    padding: 6rem 3rem 3rem; }
    .section__07__part5__ribbon__content span {
      position: absolute;
      bottom: -7rem;
      left: -2rem; } }

@media (min-width: 992px) and (max-width: 1439.98px) {
  .section__07__part5__ribbon__description p {
    font-size: 1.2rem; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .section__07__item__description p {
    font-size: 1.25rem;
    margin-left: -1rem; }
  .section__07 .number--large {
    font-size: 6.5rem; }
  .section__07 .number__background {
    font-size: 11.25rem; }
  .section__07 .stat .number {
    justify-content: flex-end;
    padding-right: 2rem; }
  .section__07 .stat:after {
    right: 3rem; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .section__07 .number--large {
    font-size: 6.25rem; }
  .section__07 .stat:after {
    right: 1.25rem;
    height: 75%;
    width: 8px;
    top: 15%; }
  .section__07 .number__background {
    font-size: 11rem; } }

@media (min-width: 1440px) {
  .section__07 .stat:after {
    right: 0.75rem; }
  .section__07 .number__background {
    font-size: 12rem; }
  .section__07 .number--large {
    font-size: 7rem; } }

.section__08 .container {
  overflow: hidden; }

.section__08 h3 {
  font-family: "Oswald";
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  color: #283248; }

@media (min-width: 768px) {
  .section__08__header h3 {
    margin: 6rem 0 7rem; } }

@media (max-width: 767.98px) {
  .section__08__header h3 {
    margin: 3rem 0; } }

.section__08__part1 svg {
  width: 100%; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__08__part1 svg rect {
      height: 15px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__08__part1 svg rect {
      height: 20px; } }
  @media (max-width: 767.98px) {
    .section__08__part1 svg rect {
      height: 8px; } }

.section__08__part1__description {
  padding: 2rem; }
  .section__08__part1__description strong {
    font-family: "Oswald";
    font-weight: 600;
    color: #005ba6; }

.section__08__skyline {
  width: 100%; }

.section__08__part2 {
  margin: 10rem 0; }
  .section__08__part2 .number {
    margin-bottom: -3rem; }
    .section__08__part2 .number--large {
      color: #f9a01b;
      font-size: 6.25rem; }
    @media (max-width: 767.98px) {
      .section__08__part2 .number {
        margin-bottom: -5rem; } }
  .section__08__part2 .stat19__two {
    margin: 0 3rem; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__08__part2 .stat {
      font-size: 1.3rem; } }

.section__08__part4 {
  margin: 6rem 0; }
  .section__08__part4 p {
    padding-left: 0; }
  .section__08__part4__item {
    display: flex;
    min-height: 15rem;
    max-height: 20rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden; }
    .section__08__part4__item img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%; }
    .section__08__part4__item .number {
      font-family: "Oswald";
      font-size: 4rem; }
      @media (min-width: 1440px) {
        .section__08__part4__item .number {
          font-size: 7rem;
          padding-bottom: 2rem; } }
      @media (min-width: 1200px) and (max-width: 1439.98px) {
        .section__08__part4__item .number {
          font-size: 6rem;
          padding-bottom: 1rem; } }
    .section__08__part4__item .number {
      line-height: 6rem; }
    .section__08__part4__item .title {
      font-size: 1.5rem; }
    .section__08__part4__item__inner {
      position: absolute;
      top: 0;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%; }
    .section__08__part4__item:hover {
      color: #fff; }
    @media (min-width: 768px) {
      .section__08__part4__item:hover img {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        color: #fff;
        transition: transform 0.7s; } }
  .section__08__part4 .description {
    margin-bottom: 4rem; }
    .section__08__part4 .description ul {
      margin-left: -2.5rem; }
      .section__08__part4 .description ul li {
        background: url("/assets/images/06_empowering-diverse-learners/bullet-pt.svg") no-repeat left top;
        padding: 0.2rem 0.5rem 1.2rem 3.5rem;
        list-style: none; }
        @media (max-width: 767.98px) {
          .section__08__part4 .description ul li {
            background-size: 10%;
            padding: .1rem .5rem 1.2rem 3rem; } }
  .section__08__part4__item1-img {
    background-color: #c9a6ce; }
  .section__08__part4__item2-img {
    background-color: #7677b8; }
  .section__08__part4__item3-img {
    background-color: #71c6e7; }
    .section__08__part4__item3-img .title {
      top: 30%; }
  .section__08__part4__item4-img {
    background-color: #11a1dc; }
    .section__08__part4__item4-img .title {
      top: 30%; }
  .section__08__part4__item5-img {
    background-color: #fdcc09; }
  .section__08__part4__item6-img {
    background-color: #99cc66; }
  .section__08__part4__item7-img {
    background-color: #03af4b; }
  .section__08__part4 .description {
    font-size: 1.25rem; }
    .section__08__part4 .description p {
      margin-top: 2rem;
      font-weight: bold; }
    .section__08__part4 .description .link {
      font-weight: bold; }
      .section__08__part4 .description .link:hover, .section__08__part4 .description .link:focus {
        text-decoration: underline; }

.section__08__part5 {
  font-size: 1.25rem; }
  @media (max-width: 991.98px) {
    .section__08__part5 {
      font-size: 1rem; } }
  .section__08__part5 .row {
    height: 100%; }
  .section__08__part5 .colour-bar-small {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg); }
    .section__08__part5 .colour-bar-small svg {
      transform: scale(0.5, 1.2); }
  .section__08__part5__description {
    padding-top: 5rem;
    color: #005ba6; }
    .section__08__part5__description img {
      padding: 3rem 2rem 2rem; }
    .section__08__part5__description p {
      margin: 0; }
      @media (min-width: 1200px) and (max-width: 1439.98px) {
        .section__08__part5__description p {
          font-size: 1.75rem; } }
      @media (min-width: 1440px) {
        .section__08__part5__description p {
          font-size: 2rem;
          margin: 0; } }
  .section__08__part5__number {
    color: #005ba6;
    font-family: "Oswald";
    font-size: 2rem; }
  @media (min-width: 768px) {
    .section__08__part5 .colour-bar-small-bg {
      background: url("/assets/images/common_ui/colour-bar-small.png") no-repeat left;
      background-size: 8px 80%;
      padding: 3rem 0 3rem 3rem; } }
  .section__08__part5__graphic {
    padding: 6rem 3rem 5rem;
    position: relative; }
    .section__08__part5__graphic__header {
      width: 50%;
      position: absolute;
      top: 35%;
      left: 25%;
      text-align: center; }
      .section__08__part5__graphic__header img {
        width: 200px;
        height: 10px; }
      .section__08__part5__graphic__header span {
        color: #005ba6;
        font-size: 5rem;
        font-family: "Oswald"; }
  .section__08__part5__figures {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 -15px; }
  .section__08__part5__figure {
    position: relative;
    text-align: center; }
  .section__08__part5 .pointer-tl {
    width: 90px;
    height: 50px;
    right: 0px;
    top: 27px; }
    @media (min-width: 1200px) and (max-width: 1439.98px) {
      .section__08__part5 .pointer-tl {
        width: 65px;
        right: -24px; } }
  .section__08__part5 .pointer-tr {
    width: 90px;
    height: 50px;
    left: -20px;
    top: 27px; }
    @media (min-width: 1200px) and (max-width: 1439.98px) {
      .section__08__part5 .pointer-tr {
        left: -30px;
        width: 65px; } }
  .section__08__part5 .pointer-bl {
    width: 90px;
    height: 33px;
    right: -10px;
    top: -7px; }
    @media (min-width: 1200px) and (max-width: 1439.98px) {
      .section__08__part5 .pointer-bl {
        width: 65px; } }
  .section__08__part5 .pointer-br {
    width: 90px;
    height: 32px;
    top: -2px;
    left: 12px; }
    @media (min-width: 1200px) and (max-width: 1439.98px) {
      .section__08__part5 .pointer-br {
        width: 65px; } }

.section__08__part6 h3 {
  margin: 8rem auto 6rem;
  text-transform: uppercase; }

.section__08__part6 h4 {
  font-family: "OpenSans", "Open Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 2rem; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__08__part6 h4 {
      font-size: 1.25rem;
      margin-bottom: 1rem; } }

.section__08__part6 p {
  padding-left: 0;
  font-size: 2rem;
  font-weight: bold; }

.section__08__part6 ul {
  font-weight: normal;
  list-style-type: none;
  font-size: 1.5rem;
  line-height: 3rem;
  padding-left: 0; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__08__part6 ul {
      font-size: 1rem;
      line-height: 2rem; } }

.section__08__part6 .number--extra-small {
  font-family: "Oswald";
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10rem; }
  @media (max-width: 991.98px) {
    .section__08__part6 .number--extra-small {
      font-size: 2rem !important; } }

.section__08__part6 .row {
  margin-bottom: 1rem; }

@media (max-width: 767.98px) {
  .section__08__part6__list .colour-block.spacing {
    padding: 0 0 1rem; } }

.section__08__part6 .colour-block.spacing {
  padding: 0 0 2rem; }

.section__08__programs-offered {
  padding: 3rem 0;
  color: #005ba6; }
  .section__08__programs-offered__one {
    font-size: 2rem;
    font-weight: normal; }
    @media (max-width: 991.98px) {
      .section__08__programs-offered__one {
        font-size: 1.5rem;
        margin-left: -1rem; } }
  .section__08__programs-offered__two {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 2rem; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .section__08__programs-offered__two {
        margin-left: -2rem !important;
        margin-top: 1.5rem;
        font-weight: normal; } }
    @media (max-width: 767.98px) {
      .section__08__programs-offered__two {
        margin-left: -1rem !important;
        margin-top: 1rem;
        font-weight: normal;
        font-size: 1rem; } }
  .section__08__programs-offered .number__two span {
    font-size: 10.5rem; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .section__08__programs-offered .number__two span {
        font-size: 5rem; } }
    @media (max-width: 767.98px) {
      .section__08__programs-offered .number__two span {
        font-size: 4rem; } }
  @media (min-width: 768px) {
    .section__08__programs-offered .colour-block.spacing {
      padding: 1rem 0;
      margin-bottom: -2rem; } }
  @media (max-width: 767.98px) {
    .section__08__programs-offered .colour-block.spacing {
      padding: 1rem 0;
      margin-bottom: -1rem; } }

.section__08 .stat18 .swipe__mask {
  width: 100%; }

.bullet {
  margin-left: -3rem;
  margin-right: 0.25rem; }

.section__08__pie-chart--1 {
  margin-bottom: 3rem; }
  .section__08__pie-chart--1 .figure__one {
    margin-top: 1rem;
    margin-right: 2rem; }
  .section__08__pie-chart--1 .figure__two {
    margin-top: 1rem;
    margin-left: 5rem; }
  .section__08__pie-chart--1 .figure__three {
    margin-bottom: -1rem;
    margin-left: 1rem; }
    @media (max-width: 767.98px) {
      .section__08__pie-chart--1 .figure__three {
        margin-bottom: 0;
        margin-left: 0; } }

.section__08__pie-chart--2 .figure__one {
  margin-right: 3rem;
  margin-top: 1rem;
  padding-right: 1rem; }

.section__08__pie-chart--2 .figure__two {
  margin-top: 1rem;
  margin-left: 5rem; }
  .section__08__pie-chart--2 .figure__two .pointer-tr {
    left: -25px; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .section__08__pie-chart--2 .figure__two .pointer-tr {
        left: -56px; } }

.section__08__pie-chart--2 .figure__three {
  margin-right: 2rem;
  margin-bottom: 2rem; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__08__pie-chart--2 .figure__three {
      margin-bottom: -1rem; } }

.section__08__pie-chart--2 .figure__four {
  margin-left: 5rem;
  margin-bottom: 1rem; }
  @media (min-width: 1440px) {
    .section__08__pie-chart--2 .figure__four {
      margin-left: 9rem; } }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .section__08__pie-chart--2 .figure__four {
      padding-left: 3rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section__08__pie-chart--2 .figure__four {
      margin-bottom: -2rem;
      margin-left: 7rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__08__pie-chart--2 .figure__four {
      margin-left: 7rem; } }
  @media (max-width: 767.98px) {
    .section__08__pie-chart--2 .figure__four {
      margin-bottom: -1rem; } }

.section__08__pie-chart--2 .pointer-br {
  width: 69px;
  height: 32px;
  top: -2px;
  left: -14px; }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .section__08__pie-chart--2 .pointer-br {
      top: 0px;
      left: 26px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section__08__pie-chart--2 .pointer-br {
      left: 3px; } }

@media only screen and (max-width: 767px) {
  .section__08__part4 {
    margin: 0; }
    .section__08__part4__container {
      margin: 5rem auto;
      padding: 0 30px !important; }
    .section__08__part4__item .title {
      font-size: 1.25rem; }
  .section__08__part5__description {
    text-align: center; }
    .section__08__part5__description .colour-bar-small-bg {
      padding-top: 1rem; }
  .section__08__part6 h3 {
    text-transform: uppercase;
    font-size: 2rem; }
  .section__08__part6 ul {
    font-size: 1.25rem;
    line-height: 2.25rem; }
  .section__08__part6 .number--extra-small {
    margin-bottom: 6rem; } }

@media (max-width: 991.98px) {
  .section__08 h3 {
    font-size: 2.25rem; }
  .section__08 .section__08__part5__description img {
    padding: 2rem 1rem 1rem; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__08__pie-chart--2 .section__08__part5__graphic {
    padding-bottom: 7rem; } }

@media (max-width: 767.98px) {
  .section__08__part2 {
    margin: 0; }
    .section__08__part2 .stat19__two {
      padding: 3rem 0 23rem; }
  .section__08__part5__graphic {
    padding: 8rem 1rem; }
    .section__08__part5__graphic__header span {
      font-size: 3.5rem; }
    .section__08__part5__graphic__header img {
      font-size: 0.8rem;
      width: 85%; }
  .section__08__pie-chart--one {
    margin-bottom: 3rem; }
  .section__08 .pointer-ascending,
  .section__08 .pointer-descending,
  .section__08 .pointer-vertical,
  .section__08 .pointer-horizontal {
    position: absolute;
    width: 2px;
    background: #7f7a7a;
    height: 60px; }
  .section__08 .pointer-vertical {
    left: 75%;
    top: 100%; }
  .section__08 .pointer-horizontal {
    height: 2px;
    width: 50px;
    right: -20px;
    top: 29px; }
  .section__08 .pointer-ascending {
    transform: skew(-35deg);
    left: 31%;
    height: 30px;
    top: 120%; }
  .section__08 .pointer-descending {
    transform: skew(35deg);
    height: 40px;
    left: 50%;
    top: 120%; }
  .section__08 .figure__four .pointer-descending {
    left: 50%;
    bottom: 120%;
    top: unset; }
  .section__08 .figure__three .pointer-ascending {
    left: 62%;
    top: unset;
    bottom: 120%; }
  .section__08 .figure__three .pointer-descending {
    left: 40%;
    bottom: 120%;
    top: unset; }
  .section__08__pie-chart--2 .figure__one {
    margin-right: 1rem;
    margin-top: 0rem; }
  .section__08__pie-chart--2 .figure__three {
    margin-bottom: 0; }
    .section__08__pie-chart--2 .figure__three .pointer-ascending {
      height: 48px; }
  .section__08__pie-chart--2 .figure__four {
    margin-left: 1rem;
    padding-left: 2rem; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .section__08 h3 {
    font-size: 2.75rem; }
  .section__08__pie-chart--2 .pointer-br {
    left: -2px;
    top: 0; }
  .section__08__pie-chart--2 .figure__two {
    margin-left: 8rem; }
  .section__08__part6__list-two .spacing {
    padding-top: 0rem !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .section__08__part4__item .title {
    font-size: 1.25rem; }
  .section__08__part6__list-two .spacing {
    padding-top: 4.8rem !important; } }

@media (min-width: 1200px) {
  .offset-lg-3-half {
    margin-left: 29.16%; }
  .section__08__part2 .stat19__two {
    margin: -3rem 6rem 0; }
  .section__08 .number--large {
    font-size: 8rem; } }

@media screen and (max-width: 520px) {
  .section__08__part5 {
    font-size: 0.8rem; }
    .section__08__part5__graphic__header img {
      height: 4px;
      margin-top: -6px; }
    .section__08__part5__figure {
      font-size: 0.8rem; } }

.footnotes h2 {
  margin: 3rem 0;
  text-transform: uppercase; }

.footnotes button {
  border: none;
  background-color: transparent; }
  .footnotes button img {
    transition: all 0.25s; }

.footnotes .caret-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.25s; }

.footnotes ol {
  list-style: none;
  counter-reset: item;
  line-height: 2.5rem;
  font-size: 1.5rem;
  margin: 3rem 0 5rem;
  padding-left: 0; }

.footnotes ol li {
  counter-increment: item;
  text-indent: -1.7rem;
  margin-left: 1.7rem; }
  @media (max-width: 767.98px) {
    .footnotes ol li {
      text-indent: -1.3rem;
      margin-left: 1.3rem; } }
  @media (max-width: 991.98px) {
    .footnotes ol li {
      font-size: 1.25rem; } }

.footnotes ol li::before {
  content: counter(item) ". ";
  font-weight: bold; }

#credits {
  font-size: 1.5rem;
  line-height: 1.73;
  text-align: center;
  color: #005ba6;
  padding: 6rem 0; }

@media only screen and (max-width: 767px) {
  .footnotes h2 {
    font-size: 2.5rem !important;
    font-weight: bold; }
  .footnotes img {
    width: 2.5rem; }
  .footnotes ol {
    font-size: 1.2rem; }
  #credits {
    font-size: 1.2rem;
    padding: 3rem; } }

body {
  width: 100%; }

.colour-divider {
  position: relative;
  background: url("../images/common_ui/colour-blocks.svg") repeat-x;
  height: 16px;
  background-size: auto 16px;
  background-position-x: -8px;
  margin: -8px 0; }
  @media (max-width: 767.98px) {
    .colour-divider {
      height: 10px;
      background-size: auto 10px;
      background-position-x: -5px;
      margin: -5px 0; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .colour-divider {
      height: 20px;
      background-size: auto 20px;
      background-position-x: -10px;
      margin: -10px 0; } }
  @media (min-width: 1200px) {
    .colour-divider {
      height: 26px;
      background-size: auto 26px;
      background-position-x: -13px;
      margin: -13px 0; } }

.colour-block.spacing {
  padding: 3rem 0; }

.colour-block > img {
  height: 26px;
  margin-top: -13px;
  width: auto; }
  @media (max-width: 991.98px) {
    .colour-block > img {
      height: 10px;
      margin-top: -5px; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .colour-block > img {
      height: 16px;
      margin-top: -8px; } }

.colour-block--two {
  display: flex; }
  .colour-block--two img {
    max-width: 50%; }

.colourline {
  display: flex;
  width: 100%;
  /* Temporary: animated solution will be different */ }
  .colourline > div {
    flex: 1;
    height: 100%; }
  .colourline__1 {
    background: #FF6D22; }
  .colourline__2 {
    background: #FFA100; }
  .colourline__2 {
    background: #FFA100; }
  .colourline__3 {
    background: #C9A5D7; }
  .colourline__4 {
    background: #7577B9; }
  .colourline__5 {
    background: #72C7E7; }
  .colourline__6 {
    background: #00A1DE; }
  .colourline__7 {
    background: #FECB00; }
  .colourline__8 {
    background: #99CC66; }
  .colourline__9 {
    background: #00AF3F; }
  .colourline--nav {
    height: 6px;
    margin-top: -6px;
    padding-right: 0.5rem;
    width: calc(100% / 7);
    position: absolute;
    bottom: 0; }
    @media (min-width: 992px) {
      .colourline--nav {
        margin-left: 32px; } }
  @media (max-width: 991.98px) {
    .colourline--nav {
      padding: 0;
      width: 100%; } }

.colour-blocks--vertical {
  width: 16px;
  margin-left: -8px; }
  @media (min-width: 768px) {
    .colour-blocks--vertical {
      width: 20px;
      margin-left: -20px; } }
  @media (min-width: 1200px) {
    .colour-blocks--vertical {
      width: 26px;
      margin-left: -13px; } }

.gbc-background {
  background: url("../images/common_ui/gbc-pattern-sized.svg") repeat #f2f2f2;
  background-size: 232px; }
  .gbc-background--hide-overflow {
    overflow: hidden; }
  .gbc-background .slicer {
    width: 100%;
    height: auto;
    margin-top: -1px; }
    .gbc-background .slicer--descending--bottom {
      -webkit-transform: scale(-1, -1);
      transform: scale(-1, -1);
      margin-bottom: -1px; }
    .gbc-background .slicer--ascending {
      transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1); }
      .gbc-background .slicer--ascending--bottom {
        -webkit-transform: scale(1, -1);
        transform: scale(1, -1);
        margin-bottom: -1px; }

.dark {
  background-color: #283248;
  color: #fff; }
  .dark h3 {
    font-family: "OpenSans", "Open Sans", sans-serif;
    font-size: 3rem;
    font-weight: bold;
    margin: 5.5rem 0 5rem; }
    @media (max-width: 991.98px) {
      .dark h3 {
        font-size: 2rem;
        margin: 3rem 0; } }
    @media (max-width: 767.98px) {
      .dark h3 {
        font-size: 1.5rem; } }
  .dark .dark-description {
    font-family: "OpenSans", "Open Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center; }
    @media (max-width: 991.98px) {
      .dark .dark-description {
        font-size: 1.125rem; } }

.full-width-image {
  width: 100%;
  margin: 1rem 0;
  background-color: #bbb;
  background-position: center; }
  .full-width-image img {
    width: 100%;
    height: auto; }

/*
 * Nav
 */
#navbar {
  padding: 0;
  background: #fff; }
  @media (max-width: 991.98px) {
    #navbar {
      justify-content: flex-end; }
      #navbar .navbar-toggler {
        border: 0;
        height: 4.25rem; }
      #navbar .mav-item {
        height: 5rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    #navbar .navbar-toggler {
      margin-right: 1rem; } }

.nav {
  background: #fff;
  border-bottom: solid 1px #b8b0b0;
  flex: auto;
  padding: 0 2rem;
  align-items: center; }
  .nav .nav-item {
    flex: 1; }
    .nav .nav-item a {
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.825rem;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1rem 1.5rem; }
      .nav .nav-item a:hover, .nav .nav-item a:focus, .nav .nav-item a.active {
        font-weight: 800; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .nav .nav-item a {
      padding: 1rem 0.5rem;
      font-size: 0.75rem; } }
  @media (max-width: 991.98px) {
    .nav {
      padding: 0;
      align-items: flex-start;
      flex-direction: column;
      background: #fff; }
      .nav .nav-item {
        width: 100%;
        border-top: 1px solid #ccc; }
        .nav .nav-item a {
          padding-left: 92px;
          text-align: left; } }
  @media (max-width: 991.98px) {
    .nav .nav-item a {
      padding-left: 50px; } }

/*
 * Sections
 */
.section h2 {
  font-size: 8rem;
  margin-top: 6rem; }
  .section h2 span {
    display: block;
    text-transform: uppercase;
    font-size: 4rem;
    margin-top: -1rem; }

@media (max-width: 991.98px) {
  .section h2 {
    font-size: 4rem;
    margin-top: 4rem; }
    .section h2 span {
      font-size: 2rem;
      margin-top: 0.5rem; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .section h2 {
    font-size: 7rem; }
    .section h2 span {
      font-size: 4rem; } }

.padding {
  padding: 6.5rem 0; }
  @media (max-width: 991.98px) {
    .padding {
      padding: 4.5rem 0; } }

/*
 * Footnotes
 */
.footnotes h2 {
  font-family: "Oswald";
  font-size: 3.5rem;
  font-weight: 500;
  color: #000000; }
  @media (max-width: 991.98px) {
    .footnotes h2 {
      font-size: 2.5rem; } }

.footnote {
  font-family: "OpenSans", "Open Sans", sans-serif;
  vertical-align: super;
  font-size: 14px !important;
  font-weight: normal !important; }
  .footnote:hover, .footnote:focus {
    font-weight: bold !important; }

/*
 * Stats - Numbers
 */
.stat {
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem 3rem; }
  @media (max-width: 767.98px) {
    .stat {
      padding: 1rem 0; } }

.number {
  display: flex;
  position: relative;
  color: #f2f2f2;
  font-size: 13rem;
  font-family: "Oswald", sans-serif;
  justify-content: center;
  align-items: center; }
  .number--extra-large {
    font-size: 22rem;
    color: #005ba6; }
  .number--mild-large {
    font-size: 12.5rem;
    color: #005ba6; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .number--mild-large {
        font-size: 9.375rem; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .number--mild-large {
        font-size: 6.25rem; } }
    @media (max-width: 767.98px) {
      .number--mild-large {
        font-size: 4.6875rem; } }
  .number--large {
    position: absolute;
    font-size: 8rem;
    color: #005ba6; }
    @media (max-width: 991.98px) {
      .number--large {
        font-size: 5rem; } }
  .number--medium {
    font-size: 6.25rem;
    font-weight: normal;
    color: #005ba6; }
    @media (max-width: 991.98px) {
      .number--medium {
        font-size: 4.4rem; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .number--medium {
        font-size: 5rem; } }
  .number--small {
    font-size: 4rem;
    font-weight: normal;
    color: #f2f2f2; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .number--small {
        font-size: 2.75rem; } }
  .number--extra-small {
    font-size: 4.4rem;
    font-weight: normal;
    color: #005ba6; }
    @media (max-width: 991.98px) {
      .number--extra-small {
        font-size: 3.125rem; } }
  .number__foreground {
    position: absolute; }
  .number__background {
    font-size: 13rem;
    line-height: 12rem;
    color: #f2f2f2; }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .number__background {
        font-size: 11.125rem; } }
    @media (max-width: 767.98px) {
      .number__background {
        font-size: 8.125rem; } }

/*
 * PDF Button
 */
a.btn-pdf {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #828386;
  transition: all 0.15s ease-in;
  margin-bottom: 3rem; }
  a.btn-pdf svg {
    width: 50px;
    height: 60px;
    transition: all 0.15s ease-in; }
  a.btn-pdf:hover span {
    border-bottom: 2px solid; }
  a.btn-pdf:hover svg {
    fill: #000; }
  a.btn-pdf:focus {
    text-decoration: underline; }

.strong-blue {
  font-size: 1.5rem;
  font-weight: bold;
  color: #005ba6; }

.pointer-tl {
  background: url("/assets/images/common_ui/pointer-tl.svg") no-repeat;
  background-size: 100% auto;
  position: absolute; }

.pointer-tr {
  background: url("/assets/images/common_ui/pointer-tr.svg") no-repeat;
  background-size: 100% auto;
  position: absolute; }

.pointer-bl {
  background: url("/assets/images/common_ui/pointer-bl.svg") no-repeat bottom;
  background-size: 100% auto;
  position: absolute; }

.pointer-br {
  background: url("/assets/images/common_ui/pointer-br.svg") no-repeat bottom;
  background-size: 100% auto;
  position: absolute; }

.container {
  padding: 0 50px; }
  @media (min-width: 1440px) {
    .container {
      padding: 0 92px; } }

/*
 * Animation support
 */
.swipe {
  display: flex;
  flex-direction: column; }
  .swipe__mask {
    position: absolute;
    overflow: hidden; }

.navbar:before {
  position: absolute;
  height: 10px;
  width: 40px;
  left: 3px;
  bottom: -10px;
  color: red;
  font-weight: bold; }
  @media (max-width: 767.98px) {
    .navbar:before {
      content: 'xs dev'; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .navbar:before {
      content: 'sm dev'; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar:before {
      content: 'md dev'; } }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .navbar:before {
      content: 'lg dev'; } }
  @media (min-width: 1440px) {
    .navbar:before {
      content: 'xl dev'; } }

.height-wrap {
  display: flex;
  flex-grow: 1; }

.no-wrap {
  display: inline;
  white-space: nowrap; }
