.section__06__part1 {
  > div {
    margin: 8rem 0;
  }

  &__description {
    margin-top: 2rem;
    font-size: 1.3rem;
  }

  .number {
    &--large {
      position: static;
    }
  }
}

.section__06__part2 {
  font-size: 1.75rem;
  .number--mild-large { line-height: 12rem;}
  
  .office-link {
    font-weight: bold;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .row {
    margin-bottom: 2.5rem;
  }

  @include media-breakpoint-down(xs) {
    .fade-in-sec6-01 {
      margin-bottom: 1rem;
    }
    .row {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    &__list {
      .multiline {
        margin-left: 3.5rem;
        margin-top: -2rem;
      }
    }
  }
  @include media-breakpoint-only(sm) {
    font-size: 1rem;

    .number {
      line-height: 1.5;
    }
  }
  @include media-breakpoint-only(md) {
    font-size: 1.5rem;
  }
}

.section__06__part3 {
  margin: 13rem auto;
  img {
    width: 16.75rem;
    height: 26rem;
  }
  .stat {
    padding: 1rem 0;
  }
}

.section__06__part4 {
  .number--small {
    font-size: 3rem;
  }
  h3 {
    font-size: 3.75rem;
    font-weight: 500;
    text-align: center;
    color: #283248;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  &__card {
    margin-bottom: 8rem;
    svg {
      width: 100%;
    }
  }
  .stat-container {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
      no-repeat;
    background-size: 100% 10px;
    padding-top: 1rem;
    width: 100%;
  }
  .stat {
    background: url("/assets/images/common_ui/flag.svg") no-repeat bottom
      #005ba6;
    background-size: 105% auto;
    color: #fff;
    margin-top: -0.4rem;
    padding: 3rem 4rem 8rem;
    min-height: 33rem;
    background-position-y: calc(100% + 1px); // Try to fix thin line issue.
    p {
      margin: 0;
    }
    a {
      vertical-align: super;
      font-size: 1rem;
      padding-left: 2px;
      color: white;
    }
  }
}

//Research items
@media only screen and (min-width: 600px) and (max-width: 650px) {
  .section__06__part1 {
    padding: 0 100px !important;
    img {
      margin-left: 20% !important;
    }
  }
}

@media only screen and (min-width: 651px) and (max-width: 767px) {
  .section__06__part1 {
    padding: 0 120px !important;
    img {
      margin-left: 20% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .section__06__part1 {
    &__globe {
      width: 6rem;
    }

    &__description {
      margin-top: 0;
      text-align: center;
    }
    img {
      margin: 1rem;
      @include media-breakpoint-up(600px) {
        margin-left: 20% !important;
      }
    }
  }

  .section__06__part2 {
    font-size: 1.25rem;

      .fade-in-sec6-01 {
        img {
          width: 1.5rem;
        }
      }

    .multiline {
      margin-left: 2.5rem;
      margin-top: -1.875rem;
    }
    &__description {
      text-align: center;
      margin-top: -2rem;
      font-size: 1.375rem;
    }
  }

  .section__06__part3 {
    margin: 8rem auto;
    img {
      width: 14rem;
      height: 20rem;
      margin-left: 2.5rem;
    }
    .number {
      margin-top: 3rem;
      &--large {
        font-size: 6rem;
      }
      .number__background {
        font-size: 9.5rem;
        line-height: 1;
      }
    }
    .stat {
      font-size: 1.375rem;
    }
  }

  .section__06__part4 {
    h3 {
      font-size: 2.25rem;
    }
    &__card {
      h3 {
        font-size: 3rem;
      }
      .stat {
        min-height: 20rem;
        padding-left: 1rem;
        padding-right: 1rem;
        .number {
          justify-content: center;
          &--small {
            font-size: 2.25rem; // To fit on one line.
          }
        }
      }
      > div {
        margin-bottom: 2rem;
      }
      div:last-child {
        .stat {
          padding-bottom: 8rem;
        }
      }
    }
    &__description {
      margin: -6rem auto 2rem;
      max-width: 75%;
      text-align: center;
    }
  }
  a.btn-pdf {
    margin-bottom: 3rem;
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section__06 {
    &__part1 {
      &__description {
        font-size: 1.25rem;
      }
      img {
        max-height: 8rem;
      }
    }

    &__part2 {
      font-size: 1.125rem;
      &__description {
        font-size: 1.25rem;
      }
      .colour-block--one {
        img {
          //height:1.4rem
        }
      }

      &__list {
        // font-size: 1.3rem;
      }
    }

    &__part3 {
      img {
        height: 23rem;
      }

      .stat {
        font-size: 1.3rem;
        .number {
          &--large {
            font-size: 7rem;
          }
        }
      }
    }

    &__part4 {
      .number--small {
        font-size: 2.5rem;
      }
      &__card {
        .stat {
          min-height: 22rem;
          padding: 3rem 2rem;
        }
      }
      h3 {
        font-size: 2.5rem;
      }
    }
  }
  .btn-pdf {
    font-size: 1.3rem;
    svg {
      width: 40px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section__06 {
    &__part4 {
      h3 {
        font-size: 2.75rem;
      }
      &__card {
        .stat {
          min-height: 23rem;
          .number {
            &--small {
              font-size: 3rem;
            }
          }
        }
        div {
          padding-left: 0;
        }
        div:last-child {
          .stat {
            padding-bottom: 7rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .section__06 {
    &__part4 {
      &__card {
        .stat {
          min-height: 30rem;
          .number {
            &--small {
              font-size: 4.5rem;
            }
          }
        }
        
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .section__06 {
    &__part4 {
      &__card {
        .stat16__one {
          margin-left: 4rem !important;
        }
        .stat16__two {
          margin-right: 4rem !important;
        }        
      }
    }
  }
}