.section__04 {
  // Funding card
  &__container {
    .number {
      &--mild-large {
        margin: 5rem 0;
      }
    }
  }
  .stat07__one {
    .number {
      line-height: 1.5;
    }
  }
  &__card-container {
    margin: 6rem 0 6rem;
    @include media-breakpoint-only(md) {
      margin-top: 2rem;
    }
    > div {
      text-align: center;
      svg {
        width: 100%;
        height: auto;
      }
      .stat {
        background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
          no-repeat;
        background-size: 100% 10px;
        background-color: #005ba6;
        color: #fff;
        font-size: 2rem;
        margin-top: -8px;
        margin-bottom: 2rem;
        padding: 0;
        height: 100%;
        width: 100%;
        .number {
          margin-bottom: -2.5rem;
          line-height: 3;
        }
        span {
          padding-bottom: 2rem;
        }
      }
    }
  }
  // City
  &__city-container {
    .number {
      line-height: 12rem;
    }
    margin: 5rem auto;
    .stat {
      padding: 0;
    }
    .col-2 {
      margin-right: -1.2rem;
      margin-left: -1rem;
      .number {
        margin-bottom: -3rem;
      }
    }
    &__num1 {
      z-index: 20;
      padding-right: 3rem;
      padding-bottom: 4rem;
      p {
        margin-top: 3rem;
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }
    &__num2 {
      padding-top: 11rem;
    }
    &__num3 {
      padding-top: 5rem;
    }
    &__num4 {
      padding-top: 2rem;
    }
    &__num5 {
      padding-top: 10rem;
      p {
        margin: 0;
        font-size: 1.2rem;
      }
      .stat {
        padding: 1.8rem 1rem 2rem 2rem;
        .number {
          margin: -4rem auto !important;
        }
      }
      .circle {
        position: absolute;
        width: 11rem;
        height: 11rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 6px solid#394a50;
      }
    }

    &__toronto-line {
      width: 100%;
      height: 100%;
      margin-left: -14rem;
      object-fit: contain;
      transform: scale(1.4, 1.2);
      transform-origin: 0 0;
      margin-top: -37rem;
      img {
        width: 100%;
        height: 700px;
        @include media-breakpoint-only(lg) {
          width: 96%;
        }
      }
    }

    &__arrow-container {
      p {
        margin: -2.5rem 0 4rem;
      }
    }
  }
}

.gbc-background-cityline {
  background-position: top right !important;
  position: relative;
}
.city-line {
  // width: 100%;
  height: 50%;
  background: url(../images/common_ui/gbc-pattern-sized.svg) repeat #f2f2f2;
  z-index: 10;
  background-size: 232px;
  position: absolute;
  right: 0;
  background-position-x: right;
  top: 154px;
  background-position-y: -155px;
  @include media-breakpoint-down(xs) {
    display: none;
  }
  @include media-breakpoint-only(sm) {
    height: 70%;
  }
}

.research-areas {
  margin: 6rem 0;

  @include media-breakpoint-up(lg) {
    .container {
      padding: 0 90px 0 74px;
    }
  }

  @include media-breakpoint-only(md) {
    .container {
      padding: 0 55px 0 39px;
    }
  }

  &__column-one {
    @include media-breakpoint-up(md) {
      right: -2rem;
    }
  }
  &__column-two {
    @include media-breakpoint-up(md) {
      .research-areas__item-one {
        right: -1rem;
      }
    }
  }

  &__column-three {
    @include media-breakpoint-up(md) {
      left: -1rem;
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background-color: rgba(0, 91, 166, 0.6);
    img {
      // mix-blend-mode: multiply;
    }
    &__title {
      position: absolute;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.27;
      text-align: center;
      color: #ffffff;
      padding: 1rem;
      @include media-breakpoint-only(md) {
        font-size: 1.4rem;
      }
    }
    @include media-breakpoint-only(md) {
      margin-bottom: 0.7rem;
    }
  }

  &__number {
    .stat {
      margin-left: 1rem;
      margin-top: -2rem;
    }
    &__description {
      font-size: 22px;
      line-height: 1.91;
      color: #000;
    }

    @include media-breakpoint-up(lg) {
      .stat {
        .number--extra-large {
          font-size: 22.5rem;
          line-height: 1;
        }
      }
    }
    @include media-breakpoint-up(md) {
      .stat {
        margin-left: 3rem;
        margin-top: -3rem;
      }
      &__description {
        top: -1rem;
        left: 2rem;
      }
    }
    @include media-breakpoint-only(md) {
      .stat {
        .number--extra-large {
          font-size: 13rem;
        }
      }
      &__description {
        font-size: 19px;
      }
    }
    @include media-breakpoint-only(xs) {
      .stat {
        .number--extra-large {
          font-size: 12.5rem;
          line-height: 12rem;
        }
      }
    }
  }

  .last-row-images {
    .research-areas__item {
      margin-right: 1rem;
    }
    :last-child {
      margin-right: 0;
    }
  }
}

// Arrow container in mobile
@media only screen and (max-width: 489px) {
  .section__04__city-container__arrow-container {
    margin-top: 6.5rem !important;
  }
}
@media only screen and (min-width: 490px) and (max-width: 530px) {
  .section__04__city-container__arrow-container {
    margin-top: 8rem !important;
  }
}
@media only screen and (min-width: 531px) and (max-width: 650px) {
  .section__04__city-container__arrow-container {
    margin-top: 9rem !important;
  }
}
@media only screen and (min-width: 651px) and (max-width: 767px) {
  .section__04__city-container__arrow-container {
    margin-top: 10rem !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .section__04__city-container__toronto-line {
    margin-left: -7rem !important;
    -webkit-transform: scale(1.8,1.8) !important;
    transform: scale(1.8,1.8) !important;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .section__04 {
    //funding card
    &__container {
      .number {
        &--mild-large {
          margin: 0;
        }
      }
    }

    &__card-container {
      margin: 3rem 0 3rem;
      > div {
        text-align: center;
        margin-bottom: 3rem;
        svg {
          width: 100%;
          height: auto;
        }
        .stat {
          background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
            no-repeat top center;
          background-size: 100% 10px;
          font-size: 1.375rem;
          background-color: #005ba6;
          .number {
            margin-bottom: -2.5rem;
            font-size: 2.5rem;
            line-height: 8rem;
            &--small {
              font-size: 2.5rem;
            }
          }
        }
      }
    }

    //City
    &__city-container {
      padding: 0 5rem;
      p {
        font-size: 1.125rem;
      }
      &__num1 {
        padding: 0;
        p {
          margin-top: -1rem;
          margin-left: 3rem;
          margin-right: 3rem;
        }
        .number--mild-large {
          font-size: 7rem;
        }
      }
      &__num2 {
        padding: 0;
        p {
          margin-top: -3.5rem;
        }
      }
      &__num3 {
        padding: 0;
        p {
          margin-top: -3.5rem;
        }
      }
      &__num4 {
        padding-top: 0;
        p {
          margin-top: -3.5rem;
        }
      }
      &__num5 {
        padding-top: 3rem;
        justify-content: center;
        display: flex;
        .circle {
          margin-top: -1rem;
          margin-left: -1rem;
        }
        .stat {
          padding: 1.8rem 2rem 2rem;
        }
      }
      &__toronto-line {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-left: -4.2rem;
        -webkit-transform: scale(1.7,1.8);
        transform: scale(1.7,1.8);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        margin-top: 2rem;
        img {
          height: 174px;
        }
      }
      &__arrow-container {
        margin-top: 7.5rem;
        img {
          width: 3rem;
          height: 7rem;
        }
        &__number {
          margin-top: -3rem !important;
        }
      }
      &__arrow-container + p {
        margin-bottom: 3rem;
      }
    }
  }

  .research-areas {
    margin: 6rem 0;

    &__number {
      &__description {
        font-size: 1.25rem;
        line-height: 1.75;
        margin: 2rem 0 4rem;
        text-align: center;
      }
    }
    &__item {
      margin-bottom: 3rem;
      &__title {
        font-size: 1.875rem;
      }
    }
  }
}

// Tablet portrait
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section__04 {
    &__container {
      max-width: 90%;
      .number {
        &--mild-large {
          margin: 1rem 0 1rem;
          font-size: 7rem;
        }
      }
    }

    &__card-container {
      > div {
        margin-bottom: 3rem;
      }
      .number {
        &--small {
          font-size: 3rem;
        }
      }
      span {
        font-size: 1.3rem;
      }
    }
  }

  .research-areas {
    margin: 6rem 0;

    &__number {
      margin-bottom: 3rem;
      .number--extra-large {
        font-size: 15rem;
      }
      &__description {
        font-size: 1.3rem;
      }
    }
    &__item {
      margin-bottom: 3rem;
      &__title {
        font-size: 1.5rem;
      }
    }
  }
}

//Tablet portrait
@include media-breakpoint-only(sm) {
  .section__04 {
    &__city-container {
      p {
        font-size: 1.2rem;
        text-align: center;
      }
      &__num1 {
        padding-right: 0;
        padding-bottom: 6rem;
        .number--mild-large {
          font-size: 9rem;
        }
        p {
          margin: 0rem 0rem 2rem;
          padding: 0 1rem;
          font-size: 1.4rem;
        }
      }
      &__num2 {
        .number {
          line-height: 6rem;
        }
        .stat-one {
          margin-bottom: 5rem;
        }
        padding-top: 0rem;
      }
      &__num3 {
        .number {
          line-height: 4rem;
        }
        .stat-one {
          margin-bottom: 4rem;
        }

        .circle {
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          width: 10rem;
          height: 10rem;
          border: 4px solid #394a50;
          margin-left: 2rem;
          .stat-two {
            p:first-child {
              margin-bottom: 0;
              margin-top: 1rem;
            }
          }
        }
        padding-top: 1rem;
        p {
          padding:0 2rem;
        }
      }

      &__toronto-line {
        -webkit-transform: scale(1.5, 1.7);
        transform: scale(1.5, 1.7);
        margin-top: -38rem;
        margin-left: -9rem;
      }
      &__arrow-container {
        margin-top: 7rem;
        &__number {
          margin-top: -1rem;
        }
      }
    }
  }
}

// Tablet landscape
@include media-breakpoint-only(md) {
  .section__04 {
    &__city-container {
      p {
        font-size: 1.2rem;
      }
      &__num1 {
        right: 1rem;
        padding-bottom: 6rem;
        .number--mild-large {
          font-size: 10.5rem;
        }
        p {
          margin: 0rem 0.5rem 2rem;
        }
      }
      &__num2 {
        .number {
          line-height: 6rem;
        }
        left: 1rem;
        padding-top: 9rem;
      }
      &__num3 {
        .number {
          line-height: 6rem;
        }
        padding-left: 0;
        padding-right: 0;
        padding-top: 4rem;
      }
      &__num4 {
        .number {
          line-height: 6rem;
        }
        left: 0.5rem;
        padding-left: 0;
        padding-right: 0;
        padding-top: 1rem;
        p {
          padding: 0 1rem;
        }
      }
      &__num5 {
        left: 1rem;
        padding-top: 6rem;
        .circle {
          width: 10rem;
          height: 10rem;
          border: 4px solid #394a50;
        }
        .stat {
          padding: 1rem 1rem 3rem 1rem;
        }
      }

      &__toronto-line {
        -webkit-transform: scale(1.7, 1.8);
        transform: scale(1.7, 1.8);
        margin-top: -50rem;
        margin-left: -16rem;
      }
      &__arrow-container {
        margin-top: 12rem;
        &__number {
          margin-top: -1rem;
        }
      }
    }
  }
}

// Desktop
@include media-breakpoint-only(lg) {
  .section__04 {
    &__city-container {
      &__num1 {
        padding-bottom: 6rem;
        right: 1rem;
        p {
          margin: 2rem 0.5rem;
        }
      }
      &__num2 {
        left: 2rem;
        .number {
          line-height: 6rem;
        }
        padding-top: 12rem;
      }
      &__num3 {
        .number {
          line-height: 6rem;
        }
        padding-top: 6rem;
      }
      &__num4 {
        .number {
          line-height: 6rem;
        }
        left: 1rem;
        padding-top: 1rem;
      }
      &__num5 {
        padding-top: 7rem;
        .stat {
          padding: 1.2rem 1rem 3rem 1rem;
        }
      }
      &__toronto-line {
        -webkit-transform: scale(1.77, 1.8);
        transform: scale(1.77, 1.8);
        margin-top: -52rem;
        margin-left: -20rem;
      }
      &__arrow-container {
        margin-top: 16rem;
        &__number {
          margin-top:-1rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .section__04 {
    &__city-container {
      .number--mild-large {
        font-size: 14rem;
      }
      .number--extra-small {
        font-size: 5rem;
      }
      p {
        font-size: 1.6rem;
      }
      &__num1 {
        padding-bottom: 6rem;
        p {
          margin: 2rem 0.5rem;
        }
      }
      &__num2 {
        .number {
          line-height: 6rem;
        }
        left: 2rem;
        padding-top: 13rem;
      }
      &__num3 {
        .number {
          line-height: 6rem;
        }
        padding-top: 6rem;
      }
      &__num4 {
        .number {
          line-height: 6rem;
        }
        right: 1rem;
        padding-top: 0rem;
      }
      &__num5 {
        padding-top: 7rem;
        right: 2rem;
        .stat {
          padding: 1.2rem 1rem 3rem 1rem;
        }
        .circle {
          width: 12rem;
          height: 12rem;
        }
      }
      &__toronto-line {
        -webkit-transform: scale(1.6, 1.8);
        transform: scale(1.6, 1.8);
        margin-top: -50rem;
        margin-left: -20rem;
      }
      &__arrow-container {
        margin-top: 19rem;
        &__number {
          margin-top: -1rem;
        }
      }
    }
  }
}


@media only screen and (min-width: 1900px) {
  .section__04 {
    &__city-container {
      &__num3 {
        .number {
          line-height: 6rem;
        }
        left: 3rem;
        padding-top: 6rem;
      }
      &__num4 {
        .number {
          line-height: 6rem;
        }
        left: 2rem;
        padding-top: 0rem;
      }
      &__num5 {
        padding-top: 7rem;
        left: 4rem;
      }
      &__toronto-line {
        -webkit-transform: scale(1.7, 1.8);
        transform: scale(1.7, 1.8);
        margin-top: -50rem;
        margin-left: -21rem;
      }
    }
  }
}
