// Custom.scss

// Variables
@import "colours.scss";
$gbc-blue: #005293;
$gbc-number-blue: #005ba6;
// Bootstrap Variable Overrides
$body-color: #000;
$theme-colors: (
  "primary": $gbc-blue
);
$font-family-sans-serif: "OpenSans", "Open Sans", sans-serif;

//** Global textual link color.
$link-color: #000 !default;
//** Link hover decoration.
$link-hover-decoration: none !default;

$grid-breakpoints: (
  xs: 0px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px
);

$container-max-widths: (
  sm: 860px,
  md: 960px,
  lg: 1200px, // lg: 1140px,
  xl: 1440px //xl: 1380px
);
// Include only parts of Bootstrap that we need.
// @import "node_modules/bootstrap/scss/bootstrap";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";

// Additional scss files can be imported here.
@import "type.scss";
@import "layout.scss";
@import "nav.scss";

// Common components.

// Sections.
@import "00.scss";
@import "01.scss";
@import "02.scss";
@import "03.scss";
@import "04.scss";
@import "05.scss";
@import "06.scss";
@import "07.scss";
@import "08.scss";
@import "footnotes.scss";

body {
  width: 100%;
}

// Colour blocks, divider
.colour-divider {
  position: relative;
  background: url("../images/common_ui/colour-blocks.svg") repeat-x;
  height: 16px;
  background-size: auto 16px;
  background-position-x: -8px;
  margin: -8px 0;
    
  @include media-breakpoint-only(xs) {
    height: 10px;
    background-size: auto 10px;
    background-position-x: -5px;
    margin: -5px 0;
  }
  @include media-breakpoint-only(sm) {
    height: 20px;
    background-size: auto 20px;
    background-position-x: -10px;
    margin: -10px 0;
  }
  // @include media-breakpoint-up(md) { ... }
  @include media-breakpoint-up(lg) {
    height: 26px;
    background-size: auto 26px;
    background-position-x: -13px;
    margin: -13px 0;
  }
  // @include media-breakpoint-up(xl) { ... }
}

.colour-block {
  // margin: 0 -0.5rem;
  &.spacing {
    padding: 3rem 0;
  }
  > img {
    height: 26px;
    margin-top: -13px;
    width: auto;
    // float: left;
    @include media-breakpoint-down(sm) {
      height: 10px;
      margin-top: -5px;
    }
    @include media-breakpoint-between(sm, md) {
      height: 16px;
      margin-top: -8px;
    }
  }

  &--two {
    display: flex;
    img {
      max-width: 50%;
    }
  }
  
}

// Colourblocks
.colourline {
  display: flex;
  width: 100%;
  > div {
    flex: 1;
    height: 100%;
  }
  &__1 {
    background: $gbc-1;
  }
  &__2 {
    background: $gbc-2;
  }
  &__2 {
    background: $gbc-2;
  }
  &__3 {
    background: $gbc-3;
  }
  &__4 {
    background: $gbc-4;
  }
  &__5 {
    background: $gbc-5;
  }
  &__6 {
    background: $gbc-6;
  }
  &__7 {
    background: $gbc-7;
  }
  &__8 {
    background: $gbc-8;
  }
  &__9 {
    background: $gbc-9;
  }

  /* Temporary: animated solution will be different */
  &--nav {
    height: 6px;
    margin-top: -6px;
    padding-right: 0.5rem;
    width: calc(100% / 7); // # of sections
    position: absolute;
    bottom: 0;
    @include media-breakpoint-up(md) {
      margin-left: 32px;
    }
  }

  @include media-breakpoint-down(sm) {
    &--nav {
      padding: 0;
      width: 100%;
    }
  }
}
.colour-blocks--vertical {
  width: 16px;
  margin-left: -8px;
  @include media-breakpoint-up(sm) {
    width: 20px;
    margin-left: -20px;
  }
  @include media-breakpoint-up(lg) {
    width: 26px;
    margin-left: -13px;
  }
}

.gbc-background {
  background: url("../images/common_ui/gbc-pattern-sized.svg") repeat #f2f2f2;
  background-size: 232px;

  &--hide-overflow {
    overflow: hidden;
  }

  .slicer {
    width: 100%;
    height: auto;
    margin-top: -1px;

    &--descending {
      &--bottom {
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);
        margin-bottom: -1px;
      }
    }

    &--ascending {
      transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);

      &--bottom {
        -webkit-transform: scale(1, -1);
        transform: scale(1, -1);
        margin-bottom: -1px;
      }
    }
  }
}

.dark {
  background-color: #283248;
  color: #fff;
  h3 {
    font-family: $font-family-sans-serif;
    font-size: 3rem;
    font-weight: bold;
    margin: 5.5rem 0 5rem;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      margin: 3rem 0;
    }
    @include media-breakpoint-only(xs) {
      font-size: 1.5rem;
    }
  }
  .dark-description {
    font-family: $font-family-sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 1.125rem;
    }
  }
}

.full-width-image {
  width: 100%;
  margin: 1rem 0;

  background-color: #bbb;
  background-position: center;

  img {
    width: 100%;
    height: auto;
  }
}

/*
 * Nav
 */
#navbar {
  padding: 0;
  background: #fff;

  @include media-breakpoint-down(sm) {
    justify-content: flex-end;
    .navbar-toggler {
      border: 0;
      height: 4.25rem;
    }
    .mav-item {
      height: 5rem;
    }
  }

  @include media-breakpoint-only(sm) {
    .navbar-toggler {
      margin-right: 1rem;
    }
  }
}

.nav {
  background: #fff;
  border-bottom: solid 1px #b8b0b0;
  flex: auto;
  padding: 0 2rem;
  align-items: center;
  .nav-item {
    flex: 1;
    a {
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.825rem;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1rem 1.5rem;

      &:hover,
      &:focus,
      &.active {
        font-weight: 800;
      }
    }
  }
  .nav-link {
    &.active {
    }
  }

  @include media-breakpoint-only(md) {
    .nav-item {
      a {
        padding: 1rem 0.5rem;
        font-size: 0.75rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
    .nav-item {
      width: 100%;
      border-top: 1px solid #ccc;
      a {
        padding-left: 92px;
        text-align: left;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .nav-item {
      a {
        padding-left: 50px;
      }
    }
  }
}

/*
 * Sections
 */

.section {
  h2 {
    font-size: 8rem;
    margin-top: 6rem;
    span {
      display: block;
      text-transform: uppercase;
      font-size: 4rem;
      margin-top: -1rem;
    }
  }
  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 4rem;
      margin-top: 4rem;
      span {
        font-size: 2rem;
        margin-top: 0.5rem;
      }
    }
  }

  @include media-breakpoint-between(sm, md) {
    h2 {
      font-size: 7rem;
      span {
        font-size: 4rem;
      }
    }
  }
}

.padding {
  padding: 6.5rem 0;
  @include media-breakpoint-down(sm) {
    padding: 4.5rem 0;
  }
}

/*
 * Footnotes
 */

.footnotes {
  h2 {
    font-family: "Oswald";
    font-size: 3.5rem;
    font-weight: 500;
    color: #000000;
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
    }
  }
}

.footnote {
  font-family: $font-family-sans-serif;
  vertical-align: super;
  font-size: 14px !important;
  // margin-left: -2px;
  font-weight: normal !important;
  &:hover, &:focus {
    font-weight: bold !important;
  }
}


/*
 * Stats - Numbers
 */
.stat {
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem 3rem;

  @include media-breakpoint-down(xs) {
    padding: 1rem 0;
  }
}
.number {
  display: flex;
  position: relative;
  color: #f2f2f2;
  font-size: 13rem;
  // line-height: 12rem;
  font-family: "Oswald", sans-serif;
  justify-content: center;
  align-items: center;
  &--extra-large {
    font-size: 22rem;
    color: $gbc-blue;
  }
  &--mild-large {
    font-size: 12.5rem;
    color: $gbc-number-blue;
    @include media-breakpoint-only(md) {
      font-size: 9.375rem;
    }
    @include media-breakpoint-only(sm) {
      font-size: 6.25rem; //5rem;
    }
    @include media-breakpoint-down(xs) {
      font-size: 4.6875rem;
    }
  }
  &--large {
    position: absolute;
    font-size: 8rem;
    color: $gbc-blue;
    @include media-breakpoint-down(sm) {
      font-size: 5rem;
    }
  }
  &--medium {
    font-size: 6.25rem;
    font-weight: normal;
    color: $gbc-number-blue;
    @include media-breakpoint-down(sm) {
      font-size: 4.4rem;
    }
    @include media-breakpoint-only(md) {
      font-size: 5rem;
    }
  }
  &--small {
    font-size: 4rem;
    font-weight: normal;
    color: #f2f2f2;
    @include media-breakpoint-only(md) {
      font-size: 2.75rem;
    }
  }
  &--extra-small {
    font-size: 4.4rem;
    font-weight: normal;
    color: $gbc-number-blue;
    @include media-breakpoint-down(sm) {
      font-size: 3.125rem;
    }
  }
  &__foreground {
    position: absolute;
  }
  &__background {
    font-size: 13rem;
    line-height: 12rem;
    color: #f2f2f2;

    @include media-breakpoint-between(sm,md) {
      font-size: 11.125rem;
    }
    @include media-breakpoint-only(xs) {
      font-size: 8.125rem;
    }
  }
}

/*
 * PDF Button
 */

a.btn-pdf {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #828386;
  transition: all 0.15s ease-in;
  margin-bottom: 3rem;
  svg {
    width: 50px;
    height: 60px;
    transition: all 0.15s ease-in;
  }
  &:hover {
    span {
      border-bottom: 2px solid;
    }
    svg {
      fill: #000;
    }
  }
  &:focus {
    text-decoration: underline;
  }
}

.strong-blue {
  font-size: 1.5rem;
  font-weight: bold;
  color: $gbc-lightblue;
}

// Some details here should move to section scss.
.pointer-tl {
  background: url("/assets/images/common_ui/pointer-tl.svg") no-repeat;
  background-size: 100% auto;
  position: absolute;
}
.pointer-tr {
  background: url("/assets/images/common_ui/pointer-tr.svg") no-repeat;
  background-size: 100% auto;
  position: absolute;
}
.pointer-bl {
  background: url("/assets/images/common_ui/pointer-bl.svg") no-repeat bottom;
  background-size: 100% auto;
  position: absolute;
}
.pointer-br {
  background: url("/assets/images/common_ui/pointer-br.svg") no-repeat bottom;
  background-size: 100% auto;
  position: absolute;
}


.container {
  padding: 0 50px;
  // @include media-breakpoint-only(md) {
  //   padding: 0 92px;
  // }
  @include media-breakpoint-up(xl) {
    padding: 0 92px;
  }
}

/*
 * Animation support
 */
.swipe {
  display: flex;
  flex-direction: column;
  &__mask {
    position: absolute;
    overflow: hidden;
  }
  &__background {

  }
}



.navbar {
  &:before {
    position: absolute;
    height: 10px;
    width: 40px;
    left: 3px;
    bottom: -10px;
    color: red;
    font-weight: bold;
    @include media-breakpoint-only(xs) {
      content: 'xs dev';
    }
    @include media-breakpoint-only(sm) {
      content: 'sm dev';
    }
    @include media-breakpoint-only(md) {
      content: 'md dev';
    }
    @include media-breakpoint-only(lg) {
      content: 'lg dev';
    }
    @include media-breakpoint-only(xl) {
      content: 'xl dev';
    }
  }
}

.height-wrap {
  display: flex;
  flex-grow: 1;
}

.no-wrap {
  display: inline;
  white-space: nowrap;
}