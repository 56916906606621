.section__02 {
  &__network-description {
    margin: 9rem auto 14rem;
  }
  &__img-medium {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14rem;

    @include media-breakpoint-only(md) {
      height: 10rem;
    }

    @include media-breakpoint-only(sm) {
      height: 8rem;
    }

    @include media-breakpoint-only(xs) {
      height: auto;
    }
    
    img {
      width: 100%;
      height: auto;
      padding: 2rem;
    }
  }
  .padding {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .stat__two {
    // Try and occlude the normal top left one to make a straight line.
    .pointer-tl {
      width: 40px;
      right: 0;
      background-size: 103px;
    }
  }
  .pointer-tl {
    width: 4rem;
    height: 50px;
    right: -1.5rem;
    top: 4rem;
  }
  .pointer-tr {
    width: 4rem;
    height: 50px;
    left: -21px;
    top: 64px;
  }
  .pointer-bl {
    width: 4rem;
    height: 40px;
    right: -1.5rem;
    top: 30px;
  }
  .pointer-br {
    width: 4rem;
    height: 50px;
    left: -21px;
    top: 50px;
  }

  &__colour-blocks-vertical {
    position: absolute;
    left: 50%;
    z-index: 99;
  }

  &__part1 {
    .stat {
      font-size: 1.75rem;
    }
  }
}

.pie-chart {
  position: relative;
  &__figures {
    position: absolute;
    pointer-events: none; // So that [2] is clickable.
    // box-sizing: content-box;
    width: 100%;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 2rem;
  }
  &__graphic {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    // box-sizing: content-box;
    img {
      width: 50%;
    }
  }
  h3 {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    font-size: 2rem;
    margin-top: -1em;
    left: 0;
    right: 0;
  }
  .number--medium {
    line-height: 1;
  }
}


@include media-breakpoint-down(xs) {
  .section__02 {

    &__colour-block {
      &__one {
        img {
          height: 1rem;
        }
      }
    }
    .stat {
      font-size: 1.375rem;
      .number {
        &--large {
          font-size: 8rem;
        }
        &__background {
          font-size: 10.5rem;
        }
      }
    }
    
    &__network-description {
      margin-top: -2rem;
      margin: 9rem auto 5rem;
      .stat {
        padding-top: 0;
        padding-bottom: 1rem;
        .number--medium {
          font-size: 5.625rem;
        }
      }
    }

    &__colour-blocks-vertical {
      display: none;
    }
    &__colour-block {
      width: 100%;
    }
  }


  .pie-chart {

    .pointer-ascending,
    .pointer-descending,
    .pointer-vertical,
    .pointer-horizontal {
      position: absolute;
      width: 2px;
      background: #7f7a7a;
      height: 60px;
    }
    .pointer-vertical {
      left: 75%;
      top: 100%;
    }
    .pointer-horizontal {
      height: 2px;
      width: 50px;
      right: -20px;
      top: 29px;
    }
    .pointer-ascending {
      transform: skew(-45deg);
      left: 77%;
      height: 30px;
      top: -25%;
    }
    .pointer-descending {
      transform: skew(35deg);
      height: 40px;
      left: 117%;
      top: 62%;
    }
    
    .stat {
      position: absolute;
      width: 45%;
    }
    .stat__one {
      top: 4rem;
      left: 15px;
      .pointer-tl {
        right: 19rem;
        top: 25rem;
        display: none;
      }
    }
    .stat__two {
      top: 14rem;
      // Try and occlude the normal top left one to make a straight line.
      .pointer-tl {
        width: 90px;
        left: 10rem;
        top: 20rem;
        background-size: 120px;
        display: none;
      }
    }

    .stat__three {
      top: 23rem;
      left: 60px;
      .pointer-bl {
        // right: 22rem;
        // top: 10rem;
        display: none;
      }
    }
    .stat__four {
      margin-top: 1rem;
      width: 50%;
      right: -10px;
      .pointer-tr {
        // left: 27rem;
        // top: -20rem;
        display: none;
      }
    } 
    h3 {
      font-size: 1.625rem;
      margin-top: 1rem;
    }
    &__graphic {
      &__wrap {
        padding: 17rem 0 8rem 45%;
      }
      img {
        margin-top: -50%;
        width: 100%;
        visibility: visible;
        margin-left: 20px;
      }
    }
    h3 {
      position: static;
    }

    .number--medium {
      font-size: 1.625rem;
      line-height: 1;
    }
    .stat {
      font-size: 1rem;
    }

    .number__background {
      font-size: 7.5rem;
    }
  } 
}


@media only screen and (min-width: 768px) and (max-width:1200px) {
  .section__02 {
    &__network-description {
      margin: 9rem auto 7rem;
      .stat {
        padding: 0;
        font-size: 1.2rem;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .section__02 .pie-chart {
    .stat {
      width: 25%;
      position: absolute;
    }
    .stat__two {
      top: 33%;
    }
    .stat__three {
      top: 66%;
    }
    .stat02__one {
      img.chart {
        // We need to render the image to get
        // its height and width for the SVG.
        visibility: hidden;
      }
    }
  }
}


@include media-breakpoint-only(xs) {
  .section__02 {
    &__part1 {
      .stat {
        font-size: 1.375rem;

        .number--large {
          font-size: 5.625rem;
        }
        .number__background {
          font-size: 8.125rem;
          line-height: 9rem;
        }
      }
      .stat01__one {
        margin-bottom: 1rem;
      }
    }
    .number--medium {
      line-height: 1.5;
    }
  }

}

@include media-breakpoint-only(sm) {
  .section__02 .pie-chart {
    &__graphic {
      margin-top: 5rem;
      margin-bottom: 5rem;  
    }
    h3 {
      font-size: 1.25rem;
    }
    .number--medium {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }
    .stat {
      font-size: 1rem;
      position: absolute;
      padding: 1rem 0;
    }
    .stat__one {
      left: 6%;
      top: -4%;
    }
    .stat__two {
      top: 26%;
      left: -4%;
    }
    .stat__three {
      left: 8%;
    }
    .stat__four {
      left: 78%;
    }
    .pointer-bl {  
      right: -2.5rem;
      top: 6px;
    }
    .pointer-tl {
      right: -2rem;
      top: 2rem;
    }
    .pointer-tl {
      right: -2.5rem;
    }
    .pointer-tr {
      left: -48px;
      top: 38px;
    }
    .stat__two .pointer-tl {
      right: -1.5rem;
    }
    
  }

  .section__02 {
    &__part1 {
      .stat {
        font-size: 1.25rem;

        .number--large {
          font-size: 5rem;
        }
        .number__background {
          font-size: 7.5rem;
          line-height: 9rem;
        }
      }
    }

    &__network-description {
      .number--medium {
        line-height: 2;
        font-size: 4rem;
      }
    }
  }

}


@include media-breakpoint-only(md) {
  .section__02 {
    .pie-chart {
      h3 {
        font-size: 1.5rem;
      }
      .number--medium {
        font-size: 3.125rem;
        margin-bottom: 0.75rem;
      }
      .stat {
        font-size: 1.125rem;
        width: 25%;
        position: absolute;
        padding: 1rem;
      }
      .stat__one {
        left: 6%;
        top: -12%;
      }
      .stat__two {
        top: 26%;
      }
      .stat__three {
        left: 8%;
      }
      .stat__four {
        left: 74%;
      }
    }

    &__part1 {
      .stat {
        font-size: 1.5rem;
      }
    }
    &__network-description {
      .number--medium {
        font-size: 5rem;
      }
    }
  }
}



@include media-breakpoint-only(lg) {
  .section__02 .pie-chart {
    .number--medium {
      font-size: 4rem;
      margin-bottom: 0.75rem;
    }
    .stat {
      font-size: 1.125rem;
      padding: 1rem 2rem;
      width: 25%;
      position: absolute;
    }
    .stat__one {
      left: 6%;
      top: -6%;
      width: 30%
    }
    .stat__three {
      left: 4%;
    }
    .stat__four {
      left: 72%;
    }

    .pointer-tl {
      right: 0rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .section__02 .pie-chart {
    .number--medium {
      font-size: 5rem;
    }
    .stat {
      font-size: 1.5rem;
      width: 25%;
      position: absolute;
    }
    .stat__one {
      left: 6%;
      width: 30%
    }
    .stat__two {
      top: 33%;
      left: 0;
    }
    .stat__three {
      top: 66%;
      left: 5%;
    }
    .stat__four {
      left: 66%;
    }

    .pointer-tl {
      right: 0rem;
    }
  }
}

@media screen and (max-width: 420px) {
  .section__02 {
    .pointer-vertical {
      height: 78px;
      left: 60%;
    }
    .pointer-descending {
      height: 48px;
      left: 117%;
      top: 59%;
    }
    .stat__four {
      margin-right: -1rem;
    }
    .pie-chart__graphic {
      margin-bottom: 0rem;
    }
  }
}