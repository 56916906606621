.section__07 {

  .container {
    overflow: hidden; 
  }

  .number {
    width: 100%;
    // height: 100%;
  }
  .footnote {
    font-size: 10px!important;
  }
  .stat {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .number--large {
    position: static;
    // left: 1rem;
  }
  .number__background {
    font-size: 9rem;
    letter-spacing: 0.5rem;
  }
  &__colour-seperator {
    width: 0.6rem;
    height: 9rem;
    // right: 10rem;
    position: absolute;
  }
  &__description {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 25rem;
    p {
      text-align: start;
      font-size: 1rem;
      margin-top: 1.5rem;
      .link {
        font-weight: bold;
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  &__part1 {
    margin: 10rem 0;
    &__stat1 {
      display: flex;
      flex-direction: row;

      .number--large {
        color: #11a1dc;
      }

      .section__07__colour-seperator {
        background-color: #11a1dc;
      }

      .section__07__description {
        left: 25rem;
      }
    }

    &__stat2 {
      display: flex;
      flex-direction: row;

      .number--large {
        color: #7677b8;
      }

      &__colour-seperator {
        background-color: #7677b8;
      }
    }
  }

  &__part2 {
    &__stat1 {
      display: flex;
      flex-direction: row;

      .number--large {
        color: #fdcc09;
        left: -4rem;
      }

      &__colour-seperator {
        background-color: #fdcc09;
      }
    }
  }

  &__part3 {
    margin: 10rem 0;
    &__stat1 {
      display: flex;
      flex-direction: row;

      .number--large {
        color: #7677b8;
      }

      &__colour-seperator {
        background-color: #7677b8;
      }

      .section__07__description {
        left: 25rem;
      }
    }

    &__stat2 {
      display: flex;
      flex-direction: row;

      .number--large {
        color: #f36e29;
      }

      &__colour-seperator {
        background-color: #f36e29;
        // right: 9rem;
      }
    }
  }

  &__part4 {
    padding-bottom: 0;
    &__header {
      width: 100%;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #06112f;
      p {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        margin: 0;
        letter-spacing: 2px;
      }
      h3 {
        font-family: $font-family-sans-serif;
        font-size: 2.5rem;
        font-weight: bold;
      }
    }
    &__para-container {
      background: url("/assets/images/07_helping-neighbourhoods-thrive/Daniels-283248.jpg")
        no-repeat bottom right;
      background-size: auto 100%;
    }

    &__para {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6rem 0;
      a {
        font-weight: bold;
        &:hover, &:focus {
          text-decoration: underline;
        }
        color: #fff;
      }
    }

    img {
      width: 40rem;
      height: 41rem;
    }
  }

  &__part5 {
    border-top: 3px solid white;
    background: url("/assets/images/07_helping-neighbourhoods-thrive/TheArbour-283248.jpg")
      no-repeat bottom left;
    background-size: 35% auto;
    &__content {
      padding: 6rem 3rem 0rem;
      a {
        font-weight: bold;
        &:hover, &:focus {
          text-decoration: underline;
        }
        color: #fff;
      }
    }

    &__ribbon {
      margin: 2rem 0;
      img {
        width: 3rem;
        height: 5rem;
      }
      &__description {
        position: relative;
        overflow: hidden;
        padding: 3.5rem 0 3rem 3rem;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media screen and (min-width:992px) {
          padding: 3.5rem 0 3.5rem 3rem;
        }
        p {
          font-weight: bold;
          position: absolute;
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .section__07 {
    .number {
      text-align: center;
      &--large {
        font-size: 5rem;
        left: unset;
      }
      &__background {
        // margin-left: 3rem;
        font-size: 6.875rem;
      }
    }

    .stat {
      flex-direction: column;
      padding: 4rem 0;
    }

    &__description {
      position: static;
      margin-top: 2rem;
      p {
        font-size: 1.25rem;
        text-align: center;
      }
    }
    .section__07__colour-seperator {
      top: 9rem;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      transform: rotate(90deg) scale(0.8, 2.5);
    }

    &__part1 {
      margin: 5rem 0;
      &__stat1 {
        display: flex;
        flex-direction: row;
        .number--large {
          color: #11a1dc;
        }
      }

    }

    &__part2 {
      &__stat1 {
        margin-top: -4rem;
        .number {
          &__background {
            font-size: 8rem;
          }
        }
        &__colour-seperator {
          // right: 14rem;
        }
      }
    }

    &__part3 {
      margin: 0;
    }

    &__part4 {
      &__header {
        h3 {
          font-size: 2rem;
          text-align: center;
        }
      }
      &__para-container {
        display: flex;
        background: url("/assets/images/07_helping-neighbourhoods-thrive/Daniels-06112f.jpg")
          no-repeat bottom right;
        background-size: 100% auto;
        background-color: #06112f;
        height: 20rem;
        p {
          visibility: hidden;
        }
      }
      &__para {
        font-size: 1.25rem;
        text-align: center;
      }
    }

    &__part5 {
      background: none;
      border-top: 0;
      padding-bottom: 0;
      &__content {
        padding: 3rem 1rem;
        text-align: center;
      }
      &__ribbon {
        img {
          width: 5rem;
          height: 5rem;
          margin-bottom: 2rem;
        }
      }
      &__arbour-image-container {
        background: url("/assets/images/07_helping-neighbourhoods-thrive/TheArbour-06112f.jpg")
          no-repeat;
        background-size: auto 100%;
        background-position: left;
        height: 38rem;
        background-color: #06112f;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  .section__07 {
    &__part4 {
      p {
          font-size: 1rem;
      }
    }
  }
}
// Tablet and small desktop
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .section__07 {
    &__description {
      height: 100%;
    }
    .stat {
      .number {
        width: 100%;
      }
    }
    &__part1 {
      margin: 5rem 0;
      &__stat1 {
        margin-bottom: 3rem;
        &__colour-seperator {
          left: 15rem;
        }
      }

      &__stat2 {
        &__colour-seperator {
          left: 15rem;
        }
        .section__07__description {
          left: 24rem;
        }
      }
    }

    &__part2 {
      &__stat1 {
        margin-top: 0;
        .number--large {
          left: -3rem;
        }
        &__colour-seperator {
          left: 16rem;
        }
        .section__07__description {
          left: 25rem;
        }
      }
    }

    &__part3 {
      margin: 5rem 0;
      &__stat1 {
        &__colour-seperator {
          right: 14rem;
        }
        margin-bottom: 3rem;
      }

      &__stat2 {
        &__colour-seperator {
          right: 14rem;
        }
        .section__07__description {
          left: 24rem;
        }
      }
    }
  }
}


// small desktop
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section__07 {
    &__part1 {

      .section__07__description {
        left: 27rem;
      }

      &__stat1 {
        margin-bottom: 3rem;
        &__colour-seperator {
          left: 20rem;
        }
      }

      &__stat2 {
        &__colour-seperator {
          left: 20rem;
        }
        .section__07__description {
          left: 27rem;
        }
      }
    }

    &__part2 {
      &__stat1 {
        margin-top: 0;
        .number--large {
          left: -6rem;
        }
        &__colour-seperator {
          left: 21rem;
        }
        .section__07__description {
          left: 28rem;
        }
      }
    }

    &__part3 {
      margin: 5rem 0;
      &__stat1 {
        &__colour-seperator {
          right: unset;
        }
        .section__07__description {
          left: 27rem;
        }
        margin-bottom: 3rem;
      }

      &__stat2 {
        &__colour-seperator {
          right: unset;
        }
        .section__07__description {
          left: 27rem;
        }
      }
    }
  }
}





@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section__07 {
    .section__07__description {
      left: 22rem;
    }
    &__part2 {
      &__stat1 {
        margin-top: 0;
        .section__07__description {
          left: 31rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  .section__07__colour-seperator {
    right: 10rem;
  }
  .section__07 {
    &__part2 {
      &__stat1 {
        display: flex;
        flex-direction: row;
  
        .number--large {
          color: #fdcc09;
          left: -4rem;
        }
  
        &__colour-seperator {
          background-color: #fdcc09;
          right: 30rem;
        }
        .section__07__description {
          left: 37rem;
        }
      }
    }
  }


}


/*
 * WIP
 * Will need to clean up the absolute positioning approach above.
 * Goal: adjust between breakpoints fluidly.
 */
.section__07 {
  &__item {
    padding: 4rem 0;
    &__background {
      position: absolute !important;
    }
    &__description {
      p { 
        font-size: 1rem;
        margin-top: 1.5rem;
        .link {
          font-weight: bold;
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .number .number--large {
    justify-content: flex-end;
  }
  // Colours
  .stat:after {
    content: '';
    height: 80%;
    width: 10px;
    position: absolute;
    right: 1.75rem;
    top: 10%;
  }
  .stat17__one {
    .number--large { 
      color: #11a1dc;
    }
    .stat:after {
      background-color: #11a1dc;
    }
  }
  .stat17__two {
    .number--large { 
      color: #7677b8;
    }
    .stat:after {
      background-color: #7677b8;
    }
  }
  .stat17__three {
    .number--large { 
      color: #fdcc09;
    }
    .stat:after {
      background-color: #fdcc09;
    }
  }
  .stat17__four {
    .number--large { 
      color: #7677b8;
    }
    .stat:after {
      background-color: #7677b8;
    }
  }
  .stat17__five {
    .number--large { 
      color: #f36e29;
    }
    .stat:after {
      background-color: #f36e29;
    }
  }
}

@include media-breakpoint-down(xs) {

  .section__07 {
    &__item {
      padding: 0 0 3rem;
      &__background {
        align-self: flex-start;
        margin-top: 1.5rem;
      }
      &__description {
        p { 
          font-size: 1.25rem; 
          text-align: center;
        }
  
        a {
          font-weight: 700;
        }
      }
    }
    &__part5 {
      &__ribbon__description {
        padding: 0;
        overflow: unset;
        p {
          position: static;
        }
      }
    }
  
    .number .number--large {
      justify-content: center;
    }
    // Colours
    .stat:after {
      content: '';
      height: 8px;
      width: 80%;
      position: absolute;
      right: 10%;
      top: unset;
      bottom: 10%;
    }
  }
}

@include media-breakpoint-up(sm) {
  .section__07__part5__ribbon {
    &__content {
      padding: 6rem 3rem 3rem;
      span {
        position: absolute;
        bottom: -7rem;
        left: -2rem;
      }
    }
  }
}

@include media-breakpoint-between(md,lg) {
  .section__07 {
    &__part5 {
      &__ribbon {
        &__description {
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-between(sm,md) {
  .section__07 {
    &__item {
      &__description {
        p { 
          font-size: 1.25rem;
          margin-left: -1rem
        }
      }
    }
    .number--large {
      font-size: 6.5rem;
    }
    .number__background {
      font-size: 11.25rem;
    }
    .stat {
      .number {
        justify-content: flex-end;
        padding-right: 2rem;
      }
    }
    .stat:after {
      right: 3rem;
    }
  }
}

@include media-breakpoint-only(md) {
  .section__07 {
    .number--large {
     
    }
  }
}

@include media-breakpoint-only(lg) {
  .section__07 {
    .number--large {
      font-size: 6.25rem;
    }
    .stat:after {
      right: 1.25rem;
      height: 75%;
      width: 8px;
      top: 15%;
    }
    .number__background {
      font-size: 11rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .section__07 {
    .stat:after {
      right: 0.75rem;
    }
    .number__background {
      font-size: 12rem;
    }
    .number--large {
      font-size: 7rem;
    }
  }
}