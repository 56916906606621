
.section__01__header {
  

  background: url('/assets/images/01_main_header/header.jpg') no-repeat bottom right;
  background-size: 44% auto;
  background-position: 105% 115%;
  .row-fluid {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      height: 100%;
    }
  }
  h1 {
    max-width: 567px;
    // max-height: 248px;
    margin: 30px auto 0px;
    font-size: 95px;
    text-align: center;
    color: #005ba6;
  }
  p {
    font-family: $heading-font-family;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 8px;
    text-align: center;
    color: #283248;
    text-transform: uppercase;
  }
  .title {
    padding: 6rem 0 1rem;
    @include media-breakpoint-up(md) {
      padding: 6rem 0;
      margin-left: -6rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 10rem 0;
    }
  }
}

.section__01 {
  &__introduction {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../images/01_main_header/HeaderPattern_768x571-01.png");
    background-size: auto;
    overflow: hidden;
    &__content {
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      color: #005ba6;
      margin: 4.5rem auto;
      margin-bottom: 2.5rem;
    }
    p {
      margin-bottom: 2rem;
    }
  }

}


@include media-breakpoint-only(xs) {
  .section__01__header {
    background-size: 100% auto;
    background-position: 20px 110%;
    .title {
      margin-bottom: 18rem;
    }
    h1 {
      font-size: 3rem;
      text-align: center;
      color: #005ba6;
    }
    p { 
      font-size: 1rem;
    }
  }

  .section__01 {
    &__colour-divider {
      display: none;
    }

    &__introduction {
      &__content {
        padding: 0 3rem;
        margin: 3rem auto 0;
        p {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.75rem;
        }
      }
      .btn-pdf {
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .section__01__header {
    .title {
      margin-bottom: 22rem;
    }
    h1 {
      font-size: 4rem;
    }
  }
}
@media screen and (min-width: 620px) and (max-width: 768px) {
  .section__01__header {
    .title {
      margin-bottom: 27rem;
    }
  }
}
@include media-breakpoint-only(sm) {
  .section__01__header {
    background-size:100% auto;
    background-position: 20px 110%;
    .title {
      margin-bottom: 34rem;
    }
    h1 {
      font-size: 6rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .section__01__header {
    background-size: auto 80%;
  }
}