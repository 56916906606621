.back-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem;
  z-index: 9999;
  border-radius: 3px;
  @include media-breakpoint-down(sm) {
    right: 0.5rem;
  }
  img {
    width: 2.2rem;
    height: 1rem;
    align-self: center;
  }
  span {
    font-family: $font-family-sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    color: #005ba6;
    margin-top: 0.5rem;
    @include media-breakpoint-down(sm) {
        text-indent: 5px;
      }
  }
}