@import "colours.scss";
$gbc-lightblue: #005ba6;
$text-font-family: "OpenSans";

.section__03 {
  &__content {
    .number {
      &--large {
        position: unset;
        font-size: 12.5rem;
        color: $gbc-lightblue;
        line-height: 12rem;
      }
    }
    &__number-description {
      padding: 2rem 0 0 3.5rem;
      > div {
        font-size: 1.375rem;
        font-weight: bold;
        color: $gbc-lightblue;

        @include media-breakpoint-up(lg) {
          font-size: 1.75rem;
        }
      }
      p {
        font-size: 1rem;
        // margin-top: 3rem;
        font-weight: normal;
        color: #000000;
      }
    }

    @include media-breakpoint-up(sm) {
      margin-top: 4rem;
    }
  }
  &__colour-block {
    margin: 1rem 0 3rem;
  }

  &__three-people-description {
    strong {
      font-family: "Oswald";
      font-weight: 500;
      color: $gbc-lightblue;
    }
  }
  &__three-people {
    img {
      // width: 199px;
      // height: 132px;
      width: 100%;
      height: auto;
    }
  }
  &__video {
    margin: 8rem 0;
    .responsive-video {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;

      margin: 4rem 0;
    }
    .video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__map {
    p {
      font-size: 2rem;
    }
    &__svg {
      margin: 4rem 0 3rem;
    }
    &__countries {
      font-size: 1.375rem;
      font-weight: bold;
      color: #000000;
      margin-bottom: 10rem;
      div {
        white-space: nowrap;
      }
      &-wrap {
        padding: 0 1rem;
      }
      @include media-breakpoint-only(lg) {
        font-size: 1.17rem;
      }
      @include media-breakpoint-only(md) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.125rem;
      }
    }
  }
}

.dark {
  padding-bottom: 6rem;
  h3 { text-align: center; }
}
.skills-card-header {
  height: 7rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 3rem 0 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  div {
    align-self: center;
  }
}
.skills-card {
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #fff;
  &__description {
    background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
      no-repeat;
    background-size: 100.5% auto;
    background-color: #fff;
    font-size: 1.5rem;
    color: #000000;
    flex-grow: 1;
    background-color: #f2f2f2;
    p {
      padding: 3rem 1.5rem 3rem;
      @include media-breakpoint-between(md, lg) {
        font-size: 1.25rem;
      }
    }
  }
  &__colour-block {
    svg {
      width: 100%;
      margin-bottom: -2px;
    }
  }
  @include media-breakpoint-only(xl) {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .section__03 {
    &__content {
      .stat {
        // margin: -6rem 0 -4rem;
        .number {
          &--large {
            font-size: 8rem;
          }
        }
      }

      &__number-description {
        padding: 0;
        div {
          text-align: center;
          padding: 0 1rem;
        }
        p {
          font-size: 1.2rem;
          text-align: center;
        }
      }
    }

    &__three-people-description {
      text-align: center;
      > div {
        margin-right: 0 !important;
      }
    }

    &__three-people {
      text-align: center;    
      justify-content: center;
      display: flex;
      margin-bottom: 2rem;
    }

    &__video {
      margin: 0;
      .container {
        max-width: 100%;
        padding:0;
      }
      img {
        display: none;
      }
      .responsive-video {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        margin: 4rem 0;
      }
      .video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .gbc-background {
      background: none !important;
    }

    &__map {
      p {
        padding: 0 1rem;
        font-size: 1.3rem;
      }
      .stat {
        .number--medium {
          font-size: 3rem;
          line-height: 7rem;
        }
      }
      &__svg {
        padding: 0 1rem;
      }

      &__countries {
        font-size: 1.125rem;
        margin-bottom: 7rem;
        @media screen and (max-width:390px) {
          font-size: 0.9rem;
        }
      }
    }

    .skills-card-header {
      font-size: 1.7rem;
      margin: 0 0 1rem;
    }

    .skills-card {
      &__description {
        background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
          no-repeat;
        background-size: 100% 10px;
        font-size: 1.125rem;
        background-color: #fff;
      }
      &__colour-block {
        display: none;
      }
    }
  }
}


@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .section__03 {
    &__content {
      .number {
        &--large {
          font-size: 8rem;
        }
      }

      &__number-description {
        padding: 3rem 0 0 2rem;
        > div {
          font-size: 1.4rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }

    &__colour-block {
      margin: 1rem 0 0;
      img {
        height: 1rem;
      }
    }

    &__three-people {
      margin-top: 4rem;
    }
    &__three-people-description {
      .mr-5 p {
        font-size: 1.3rem;
      }
    }

    &__map {
      p {
        font-size: 1.5rem;
      }
      .stat {
        margin: -4rem 0 -2rem;
      }
    }

    
  }
}

// tablet portrait
@media only screen and (min-width:787px) and (max-width:991px) {
  .section__03 {
    .dark {
      padding-bottom: 6rem;
    }
    .dark-description {
      font-size: 1.3rem !important;
    } 
    .skills-card-header {
      font-size: 2.2rem;
    }
  
    .skills-card__description {
      background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
        no-repeat;
      background-size: 100% 10px;
      background-color: #fff;
      max-height: 200px;
     // height: 15rem;
      font-size: 1.5rem;
    }
  }
  
}

// tablet landscape
@media only screen and (min-width:992px) and (max-width:1199px) {
  .section__03 {
    .dark {
      padding-bottom: 8rem;
    }
    .dark-description {
      font-size: 1.4rem !important;
    }
    .skills-card-header {
      font-size: 2rem;
    }
  
    .skills-card__description {
      background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
        no-repeat;
      background-size: 100% auto;
      background-color: #fff;
      // height: 25rem;
      font-size: 1.2rem;
    }
  }
  
}
