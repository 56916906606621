.section__05__part1 {
  margin: 6rem 0;

  &__number {
    &--large {
      font-size: 16rem;
    }
    &__background {
      font-size: 25rem;
    }
  }

  &__button {
    margin-left: 3rem;
    a {
      width: 16rem;
      height: 4.25rem;
      font-size: 1.2rem;
      background-color: #005ba6;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
      border-radius: 0;
      -webkit-transition-property: none;
      transition-property: none;
      position: relative;
      margin-left: -3rem;
      &:hover {
        background-color: #004e84;
        color:#fff;
      }
    }
    a::after {
      content: "";
      background: url("/assets/images/05_fostering-entrepreneurship/button-arrow.svg")
        no-repeat center;
      background-size: 45%;
      // display: inline-block;
      width: 3rem;
      height: 4.25rem;
      right: -2.95rem;
      position: absolute;
      background-color: #2aa9e0;
    }
    a:hover:after {
      background-color:  #1B7BAB;
    }
  }

  .stat10__one {
    &__paragraph {
      margin-top: -1rem;
      z-index:99;
      position:relative;
      @include media-breakpoint-only(sm) {
        margin-top: 0;
      }
    }
    

    @include media-breakpoint-up(md) {
      padding: 1rem 0;
      .number--large {
          font-size: 14rem;
      }
      .number__background {
          font-size: 22rem;
          line-height: 1;
      }
    }
  }
}
// Arrow button alignment for safari
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  .section__05__part1 {
    &__button {
      a {
        position: relative;
      }
      a::after {
        top: -0.05rem;
        right: -2.95rem;
      }
    }
  }
}}

.section__05__part2 {
  margin: 10rem auto;
  p {
    font-size: 2.5rem;
    @include media-breakpoint-only(md) {
      font-size: 2rem;
    }
    span {
      // margin-left: 1.2rem;
      font-family: "Oswald";
      font-weight: bold;
      color: #005ba6;
    }
  }
  &__revenue {
    div:first-child {
      text-align: left;
      font-size: 2rem;
      
      @include media-breakpoint-only(md) {
        font-size: 1.75rem;
      }
    }
    &__money {
      font-family: "Oswald";
      font-size: 7rem;
      color: #005ba6;
      .footnote {
        vertical-align: text-top;
        padding-top: 3.5rem;
        margin-left: -2px;
        display: inline-block;
      }
    }
  }
}

.section__05__part3 {
  .row {
    margin: 7rem auto;
    display: flex;
    align-items: center;
  }

  .colour-bar-small {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg) scale(0.4, 1);
    position: absolute;
    left: 0;
  }

  .colour-bar-small-bg {
    background: url("/assets/images/common_ui/colour-bar-small.png") no-repeat left;
    background-size: 8px 80%;
    padding: 3rem;
    margin-left: 0;
    display: flex;
    align-content: center;
  }

  &__description {
    font-size: 1.6rem;
    position: relative;
    overflow: hidden;
    height: 12rem;
    align-items: center;
    p{
      position: absolute;
      left: 1rem;
      margin-left:2rem;
      z-index: 10;
      margin-bottom: 0;
    }
  }

  :last-child {
    .stat {
      margin-right: -4rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .section__05__part1 {
    margin: 5rem 0;
    .stat {
      font-size: 1.375rem;
      margin-top: -1rem;
      .number {
        &--large {
          font-size: 10rem;
        }
        &__background {
          font-size: 16rem;
        }
      }
    }

    &__button {
      margin-left: 0;
      justify-content: center;
      display: flex;
      a {
        width: 12rem;
        height: 3.68rem;
        font-size: 1rem;
      }
      a::after {
        content: "";
        background: url("/assets/images/05_fostering-entrepreneurship/button-arrow.svg")
          no-repeat center;
        display: inline-block;
        width: 3rem;
        height: 3.68rem;
        right: -2.95rem;
        position: absolute;
        background-color: #2aa9e0;
      }
    }
  }

  .section__05__part2 {
    margin: 5rem auto;
    p {
      font-size: 1.5rem;
      text-align: center !important;
      padding: 0;
      margin-bottom: 0.5rem;
      span {
        font-size: 1.75rem;
      }
    }
    
    img {
      width: 10rem;
      height: 9rem;
    }

    &__revenue {
      text-align: center;
      div:first-child {
        font-size: 1.5rem;
        text-align: center;
      }
      &__money {
        font-size: 3.5rem;
        .footnote {
          vertical-align: super;
          font-size: 0.1em;
          margin-left: -2px;
        }
      }
    }
  }

  .section__05__part3 {
    .row {
      margin: 3rem auto;
    }
    .stat { padding: 0;}
    .colour-bar-small-bg {
      background: url("/assets/images/common_ui/colour-bar-small-horizontal.png")
        no-repeat top center;
      background-size: 100% 7px;
      padding: 2rem 0 0 0;
      margin-left: 0;
      text-align: center;
    }

    &__description {
      height: auto;
      font-size: 1.125rem;
      p {
        position: static;
        height: auto;
        margin: 0;
      }
    }

    :last-child {
      .stat {
        margin-right: 0;
      }
    }

    .number__background {
      font-size: 8rem;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section__05__part1 {
    .stat {
      padding: 1rem 0;
      font-size: 1.1rem;
      .number {
        &--large {
          font-size: 8rem;
        }
        &__background {
          font-size: 15rem;
        }
      }
    }

    &__button {
      a {
        height: 3.25rem;
        width: 12rem;
        font-size: 1rem;
      }
      a::after {
        height: 3.25rem;
      }
    }
  }

  .section__05__part2 {
    p {
      font-size:1.5rem;
      margin-bottom: 1rem;
    }
    .colour-block--one {
      img {
        height:1rem;
      }
    }
    &__revenue {
      div:first-child {
        font-size: 1.25rem;
      }
      &__money {
        font-size: 5rem;
        .footnote {
          padding-top: 2.5rem;
        }
      }
    }
  }

  .section__05__part3 {
    .number {
      &__background {
        font-size: 9rem;
      }
    }
    .colour-bar-small-bg {
      font-size:1.4rem
    }
    &__description {
      p {
        left: 1rem;
        margin-left: 2rem;
        z-index: 10;
      }
    }
  }
}

// Make description slide under the colour bar but on the background number
@include media-breakpoint-only(md) {
  .section__05__part3 {
    .number__background {
      z-index: -5;
    }
    &__description {
      p {
        left: 1rem;
        max-width: auto !important;
        margin-left:2rem;
        z-index: -2;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  .section__05__part3 {
    .number__one {
      margin-right: -1rem;
    }
    .number__three {
      margin-right: -5rem;
    }
  }
}
@include media-breakpoint-up(md) {
  .section__05__part3 {
    .number__one {
      margin-right: -2rem;
    }
    .number__three {
      margin-right: -8rem;
    }
  }
}
@include media-breakpoint-between(sm,md) {
  .section__05__part3 {
    .colour-bar-small-bg {
      background-position-x: 12px;
    }
  }
}