.section__00 {
    background: $gbc-blue;
    .container {
        height: 97px;
            svg {
                margin: 19px auto;
                margin-left: -2rem;
            }
    }
}

@media only screen and (max-width: 767px) {
    .section__00 {
        .container {
            height: 58px;
                svg {
                    height:28px;
                    width: 43px;
                    margin: 18px auto;
                }
        }
    }
  }
// Colourblocks
.colourblock {
    display: flex;
    width: 100%;
    > div {
        flex: 1;
        height: 1rem;
    }
    &__1 {
        background: $gbc-1;
    }
    &__2 {
        background: $gbc-2;
    } 
    &__2 {
        background: $gbc-2;
    } 
    &__3 {
        background: $gbc-3;
    } 
    &__4 {
        background: $gbc-4;
    } 
    &__5 {
        background: $gbc-5;
    } 
    &__6 {
        background: $gbc-6;
    } 
    &__7 {
        background: $gbc-7;
    } 
    &__8 {
        background: $gbc-8;
    } 
    &__9 {
        background: $gbc-9;
    } 
}