.footnotes {
    h2 {
        margin: 3rem 0;
        text-transform: uppercase;
    }
    button {
      border: none;
      background-color: transparent;
      img {
        transition: all 0.25s;
      }
    }
    .caret-down {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: all 0.25s;
    }
    ol {
      list-style: none;
      counter-reset: item;
      line-height: 2.5rem;
      font-size: 1.5rem;
      margin: 3rem 0 5rem;
      padding-left: 0;
    }
    ol li {
      counter-increment: item;
      text-indent: -1.7rem;
      margin-left: 1.7rem;
      @include media-breakpoint-only(xs) {
        text-indent: -1.3rem;
        margin-left: 1.3rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
      }
    }
    ol li::before {
      content: counter(item) ". ";
      font-weight: bold;
    }
}

#credits {
  font-size: 1.5rem;
  line-height: 1.73;
  text-align: center;
  color: #005ba6;
  padding: 6rem 0;
}


@media only screen and (max-width:767px) {
  .footnotes {
    h2 {
      font-size: 2.5rem !important;
      font-weight: bold;
    }
    img {
      width: 2.5rem;
    }
    ol {
      font-size: 1.2rem;
    }
  }

  #credits {
    font-size: 1.2rem;
    padding: 3rem;
  }
}